import React, { FunctionComponent } from 'react';
import { View } from 'react-native';
import { Icon } from 'assets/components/icon';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text/Text';
import { PlusCircleIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';

export const EmptyStatePage: FunctionComponent<EmptyStateProps> = ({
  icon,
  iconColor,
  title,
  description,
  buttonText,
  onPress,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.screenContainer}>
      <Icon
        icon={icon}
        size={70}
        color={iconColor ? iconColor : undefined}
        accentColor={theme.colors.brandedText}
      />
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.description}>{description}</Text>
      {buttonText && (
        <Button
          icon={PlusCircleIcon}
          hierarchy="primary"
          onPress={onPress}
          testID={'add-meds-button'}
          logger={{ id: 'add-meds-button' }}
        >
          {buttonText}
        </Button>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
    lineHeight: 28,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.gray[900],
    marginVertical: theme.getSpacing(2),
  },
  description: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(2),
  },
}));

interface EmptyStateProps {
  icon: any;
  iconColor?: any;
  title: string;
  description: string;
  buttonText?: string;
  onPress: () => void;
}
