import React, { FunctionComponent } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps } from './types';

export const XIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M15 5L5 15M5 5L15 15"
        stroke="#748995"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
