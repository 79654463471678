import React, { FunctionComponent, PropsWithChildren, FC } from 'react';
import { View, Linking } from 'react-native';
import { Text } from 'assets/components/text/Text';
import { Icon } from 'assets/components/icon/Icon';
import theme, { makeStyles } from '../../theme';
import { getText } from 'assets/localization/localization';
import { InfoCardIcon } from '@digitalpharmacist/pharmacy-service-client-axios';
import {
  AppHeartIcon,
  BowlIcon,
  BoxIcon,
  CalendarTwoColorsIcon,
  ClipboardTwoColorsIcon,
  CovidIcon,
  CovidShotIcon,
  NurseDeskIcon,
  NurseIcon,
  PillsIcon,
  ShoppingBagWithGreenTagIcon,
  ShotIcon,
} from 'assets/icons';
import { MultiColorIconProps } from '../../icons/types';
import { onUrlPress } from '../../utils/messageUtils';
interface PromoCardProp {
  title: string;
  description: string;
  icon: string;
  linkUrl?: string;
  linkLabel?: string;
}

const PromoCard = ({
  title,
  description,
  icon,
  linkUrl,
  linkLabel,
}: PromoCardProp) => {
  const styles = useStyles();

  const CARD_ICON_MAP: {
    [key in InfoCardIcon]: FunctionComponent<
      PropsWithChildren<MultiColorIconProps>
    >;
  } = {
    bowl: BowlIcon,
    pills: PillsIcon,
    bag: ShoppingBagWithGreenTagIcon,
    virus: CovidIcon,
    virus_shot: CovidShotIcon,
    shot: ShotIcon,
    nurse: NurseIcon,
    nurse_desk: NurseDeskIcon,
    app_heart: AppHeartIcon,
    clipboard: ClipboardTwoColorsIcon,
    calendar: CalendarTwoColorsIcon,
    box: BoxIcon,
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.descriptionWrapper}>
        <Text style={styles.description}>{description}</Text>
        <Icon
          icon={CARD_ICON_MAP[icon as keyof typeof CARD_ICON_MAP]}
          size={80}
        />
      </View>
      {linkUrl && (
        <Text onPress={() => onUrlPress(linkUrl)} style={styles.linkText}>
          {linkLabel}
        </Text>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.getSpacing(2),
    marginTop: theme.getSpacing(2),
    backgroundColor: theme.palette.white,
    borderRadius: theme.roundness,
    shadowColor: theme.palette.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 3,
  },
  icon: {
    alignSelf: 'flex-end',
    marginVertical: theme.getSpacing(2),
  },
  descriptionWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.getSpacing(1),
    flex: 1,
    width: '100%',
  },
  title: {
    ...theme.lumistryFonts.label.medium.xBold,
    color: theme.colors.brandedText,
    margin: theme.getSpacing(1),
  },
  linkText: {
    ...theme.lumistryFonts.text.medium.bold,
    color: theme.palette.primary[600],
    margin: theme.getSpacing(1),
  },
  description: {
    flex: 1,
    ...theme.lumistryFonts.text.small.regular,
    margin: theme.getSpacing(1),
  },
}));

export default PromoCard;
