import { UpdatePatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Text } from 'assets/components/text';
import { DevicesIcon } from 'assets/icons';
import { Form, InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ImageBackground, Platform, View } from 'react-native';
import { Surface } from 'react-native-paper';
import patientService from '../../../../../api/patient-service';
import { AccountStackParamList } from '../../../AccountNavigation';
import { PatientPreferencesForm } from '../../patient-actions';
import { usePatientRecordState } from '../../patient-store';
import { MedicationInfoPreferences } from './MedicationInfoPreferences';
import { NotificationPreferences } from './NotificationPreferences';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import { Icon } from 'assets/components/icon';

export const CommunicationPreferences: FunctionComponent<
  PropsWithChildren<CommunicationPreferencesProps>
> = () => {
  const styles = useStyles();
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const { patientRecord } = usePatientRecordState();
  const methods = useForm<PatientPreferencesForm>({
    defaultValues: {
      prefers_paperless: patientRecord?.prefers_paperless === true,
      notifyBySms: patientRecord?.notify_by_sms === true,
      notifyByEmail: patientRecord?.notify_by_email === true,
    },
  });
  if (!patientRecord) {
    return;
  }
  const [notifyByEmail, changeNotifyByEmail] = useState<boolean>(
    patientRecord.notify_by_email === true,
  );
  const [notifyBySms, changeNotifyBySms] = useState<boolean>(
    patientRecord.notify_by_sms === true,
  );
  const [prefersPaperless, changePaperlessPreference] = useState<boolean>(
    patientRecord.prefers_paperless === true,
  );

  const handleUpdatePatientRecord = (
    newPatientRecord: UpdatePatientRecordDto,
  ) => {
    if (!patientRecord.id) {
      return;
    }
    patientService
      .updatePatientRecord(patientRecord.id, newPatientRecord)
      .then((data) => {
        usePatientRecordState.setState({
          patientRecord: {
            ...data,
          },
        });
      })
      .catch((error: Error) => logError(error));
  };

  const handleUpdateNotificationPreferences = () => {
    const updatedPreferences: UpdatePatientRecordDto = {
      notify_by_sms: notifyBySms,
      notify_by_email: notifyByEmail,
      prefers_paperless: prefersPaperless,
    };

    handleUpdatePatientRecord(updatedPreferences);
    navigation.goBack();
  };

  return (
    <InternalScreenContainer
      title={getText('communication-preferences')}
      showFooter
      buttons={[
        {
          hierarchy: 'primary',
          onPress: handleUpdateNotificationPreferences,
          text: getText('save'),
          logger: {
            id: 'save-bottom-sheet-button',
          },
        },
      ]}
    >
      <View style={styles.container}>
        <InfoCard>
          <View style={styles.cardView}>
            <View style={styles.icon}>
              <Icon size={65} icon={DevicesIcon} />
            </View>
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                {getText('communication-preferences-benefit')}
              </Text>
            </View>
          </View>
        </InfoCard>
        <View style={styles.description}>
          <Text style={styles.text}>{getText('opted-sms-email')}</Text>
          <Text style={styles.text}>{getText('sms-data-rates')}</Text>
        </View>
        <Form methods={methods}>
          <MedicationInfoPreferences
            setPrefersPaperless={changePaperlessPreference}
          />
          <NotificationPreferences
            notifyByEmail={notifyByEmail}
            notifyBySms={notifyBySms}
            setNotifyByEmail={changeNotifyByEmail}
            setNotifyBySms={changeNotifyBySms}
          />
          {/* Other Preferences */}
        </Form>
      </View>
    </InternalScreenContainer>
  );
};

//TODO: Change this to use the InfoCardComponent on shared components file

const InfoCard: FunctionComponent<
  PropsWithChildren<{
    uri?: string;
  }>
> = ({ uri, children }) => {
  const styles = useStyles();
  const innerContent = uri ? (
    <ImageBackground source={{ uri: uri }} resizeMode="cover" />
  ) : (
    children
  );

  return (
    <Surface style={[styles.button, styles.surface]}>{innerContent}</Surface>
  );
};

type CommunicationPreferencesProps = NativeStackScreenProps<
  AccountStackParamList,
  'communication-preferences'
>;

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: Platform.OS === 'web' ? 'auto' : theme.getSpacing(3),
    gap: theme.getSpacing(2),
  },
  button: {
    minWidth: 64,
    borderStyle: 'solid',
  },
  surface: {
    elevation: 2,
    borderRadius: theme.roundness,
    borderColor: theme.palette.gray[200],
    borderWidth: 1,
    backgroundColor: theme.palette.yellow[50],
    shadowColor: theme.palette.gray[900],
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
  },
  cardView: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    paddingVertical: theme.getSpacing(1.5),
    paddingHorizontal: theme.getSpacing(2),
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.getSpacing(2),
    flexWrap: 'wrap',
    flex: 1,
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  description: {
    gap: theme.getSpacing(2),
  },
  icon: {
    top: theme.getSpacing(1),
  },
}));
