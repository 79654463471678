import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useTheme } from '../../theme';
import { IconProps, MultiColorIconProps } from '../../icons/types';

/* TODO: its worth investigating https://github.com/kristerkari/react-native-svg-transformer
 * and its example usage https://github.com/kristerkari/react-native-svg-expo-example once its
 * a bit more stable so that we may import svg files directly
 */

export const Icon: FunctionComponent<
  MultiColorIconProps & { icon: FunctionComponent }
> = ({
  icon,
  size = 24,
  color,
  testID,
  accentColor,
  strokeWidth = 1.5,
  vectorEffect = 'non-scaling-stroke',
}) => {
  const theme = useTheme();

  return React.createElement<any>(icon, {
    size,
    color: color ?? theme.palette.black,
    testID,
    accentColor: accentColor ?? theme.colors.brandedText,
    strokeWidth,
    vectorEffect,
  });
};
