import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
import { IconProps } from './types';

export const BillingCircleIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID, strokeWidth }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 37 36"
      fill="none"
      color={color}
      testID={testID}
      strokeWidth={strokeWidth}
    >
      <Rect x="0.5" width="36" height="36" rx="18" fill="#9CAFB9" />
      <Path
        d="M18.5 8.83301V27.1663M22.6667 12.1663H16.4167C15.6431 12.1663 14.9013 12.4736 14.3543 13.0206C13.8073 13.5676 13.5 14.3095 13.5 15.083C13.5 15.8566 13.8073 16.5984 14.3543 17.1454C14.9013 17.6924 15.6431 17.9997 16.4167 17.9997H20.5833C21.3569 17.9997 22.0987 18.307 22.6457 18.8539C23.1927 19.4009 23.5 20.1428 23.5 20.9163C23.5 21.6899 23.1927 22.4318 22.6457 22.9787C22.0987 23.5257 21.3569 23.833 20.5833 23.833H13.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
