import React, { FunctionComponent, ComponentType } from 'react';
import {
  createBottomTabNavigator,
  BottomTabNavigationOptions,
} from '@react-navigation/bottom-tabs';
import { useTheme } from '../../theme';
import { CommonActions } from '@react-navigation/native';
const BottomTabNavigator = createBottomTabNavigator();

export const BottomTabs: FunctionComponent<BottomTabsProps> = ({ screens }) => {
  const theme = useTheme();

  return (
    <BottomTabNavigator.Navigator
      screenOptions={() => {
        return {
          tabBarStyle: {
            shadowColor: theme.palette.gray[900],
            shadowOpacity: 0.1,
            shadowOffset: { width: 0, height: -2 },
            shadowRadius: 4,
            borderTopWidth: 0,
          },
          tabBarActiveTintColor: theme.colors.brandedText,
          headerShown: false,
          tabBarAllowFontScaling: false,
        };
      }}
    >
      {screens.map((screen, index) => (
        <BottomTabNavigator.Screen
          key={index}
          name={screen.name}
          component={screen.component}
          options={{
            ...screen.options,
            tabBarAllowFontScaling: false,
            tabBarIcon: screen.icon,
            tabBarTestID: screen.testID,
            title: screen.label,
          }}
          listeners={({ navigation }) => ({
            tabPress: (e) => {
              e.preventDefault();
              const resetAction = CommonActions.reset({
                index: 0,
                routes: [
                  {
                    name: '/',
                    params: {
                      screen: 'app',
                      params: {
                        screen: screen.name,
                      },
                    },
                  },
                ],
              });
              navigation.dispatch(resetAction);
            },
          })}
        />
      ))}
    </BottomTabNavigator.Navigator>
  );
};

export interface BottomTabsProps {
  screens: Screen[];
}

interface Screen {
  component: ComponentType<any>;
  name: string;
  label: string;
  icon?: (props: {
    focused: boolean;
    color: string;
    size: number;
  }) => React.ReactNode;
  testID?: string;
  options?: BottomTabNavigationOptions;
}
