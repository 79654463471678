import {
  IngressMethod,
  IntegrationType,
  RxStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import { StackHeaderProps } from '@react-navigation/stack';
import { Button } from 'assets/components/button';
import { IconButton } from 'assets/components/icon-button';
import { TextField } from 'assets/components/text-field';
import { Text } from 'assets/components/text/Text';
import { TrashWithStripesIcon } from 'assets/icons';
import { Form, InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { logError } from 'assets/logging/logger';
import { makeStyles, useTheme } from 'assets/theme';
import axios from 'axios';
import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, View } from 'react-native';
import { Divider } from 'react-native-paper';
import { Barcode } from '../../camera/barcode/Barcode';
import { formatDate } from '../../common/datetime-utils';
import { useUserState } from '../../store/user-store';
import refillService from '../refill/refill-service';
import { FindPrescriptionFlowTestIDs } from './AddMedicationsTestIDs';
import {
  loadPrescriptions,
  setFindMedsScreenAsSeen,
} from './add-medications-actions';
import { useAddMedicationsStore } from './add-medications-store';
import { ampli } from '../../ampli';
import { TypeaheadBaseItem } from 'assets/components/typeahead/types';
import MedicationService from '../../components/medication-details/MedicationService';
import {
  TypeaheadWithTags,
  TypeaheadWithTagsProps,
} from 'assets/components/typeahead-with-tags';
import { TypeaheadWithTagsAndEmptyProps } from 'assets/components/typeahead-with-tags-and-empty';
import { AddMedicationsTypeahead } from './AddMedicationsTypeahead';

export const AddMedicationsManual: FunctionComponent<
  PropsWithChildren<AddMedicationsManualProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const locationId = useUserState((s) => s.user?.preferredPharmacyLocationId);
  const { patientRecord, locationPatientRecordId, originated, prescriptions } =
    useAddMedicationsStore();

  const methods = useForm({
    defaultValues: {
      rxNumber: '',
      drugName: '',
    },
  });

  const [isScannerVisible, setIsScannerVisible] = useState(false);
  const [drugsArray, setDrugsArray] = useState<
    { rxNumber: string; drugName: string | undefined }[]
  >([]);
  const [errors, setErrors] = useState<string[]>([]);

  const handleScan = (value: string) => {
    methods.setValue('rxNumber', value);
    setIsScannerVisible(false);
  };

  const handleOpenScanner = () => {
    setIsScannerVisible(true);
  };

  const handleScannerClose = () => {
    setIsScannerVisible(false);
  };

  const handlePrescription = () => {
    const newPrescription = {
      drugName: methods.getValues().drugName,
      rxNumber: methods.getValues().rxNumber,
    };

    setDrugsArray((current) => [...current, newPrescription]);

    ampli.medicationAdded({
      medicationAddedTime: new Date().toISOString(),
      medicationName: methods.getValues().drugName,
    });
    methods.reset();
  };

  const handleDelete = (deleteDrug: any) => {
    setDrugsArray(
      drugsArray.filter((drug) => drug.rxNumber !== deleteDrug.rxNumber),
    );
  };

  const drugNameFieldProps: TypeaheadWithTagsProps = {
    placeholder: getText('drug-name'),
    multiple: false,
    hintMessage: getText('drug-name'),
    useCustomNoOptionsValuePrefix: true,
    onChange: (value: (string | TypeaheadBaseItem)[]) => {
      value.map((val) => {
        if (typeof val === 'string') {
          return val;
        }
        methods.setValue('drugName', val.text);
        return val.value;
      });
    },
    asyncOptions: async (searchTerm: string) => {
      if (!searchTerm) return Promise.resolve([]);
      if (searchTerm.length >= 3) {
        const result =
          await MedicationService.searchPrescriptionsManually(searchTerm);
        result.unshift({ value: searchTerm, text: searchTerm });
        return result;
      } else {
        return Promise.resolve([]);
      }
    },
  };

  const handleOnPressDone = async () => {
    if (!locationId) {
      return logError(new Error('Location missing'));
    }
    if (!patientRecord) {
      return logError(new Error('Patient Record missing'));
    }
    if (!locationPatientRecordId) {
      return logError(new Error('Location Patient Record Id missing'));
    }

    const errorList: string[] = [];

    for (const newDrug of drugsArray) {
      try {
        await refillService.createPatientPrescriptions(
          locationId,
          locationPatientRecordId,
          {
            drug_name: newDrug.drugName,
            rx_number: newDrug.rxNumber,
            pharmacy_id: patientRecord.pharmacy_id,
            location_id: locationId,
            patient_id: locationPatientRecordId,
            integration_type: IntegrationType.Manual,
            ingress_method: IngressMethod.Web,
            rx_status: RxStatus.Fillable,
          },
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 409) {
          errorList.push(
            `Rx ${newDrug.rxNumber} - ${newDrug.drugName} already in your list`,
          );
        } else {
          errorList.push(
            `Internal Server error on saving Rx ${newDrug.rxNumber} - ${newDrug.drugName}`,
          );
        }
      }
    }

    setErrors(errorList);

    if (errorList.length) return;

    if (originated === 'medication') {
      await loadPrescriptions();
    }

    handleSkip();
  };

  const handleSkip = () => {
    if (originated === 'medication') {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate('app', { screen: 'medications' });
      }
    }
    if (originated === 'onboarding') {
      void setFindMedsScreenAsSeen();
      navigation.navigate('app', { screen: 'home' });
    } else {
      navigation.navigate('app', { screen: 'medications' });
    }
  };

  const isNumber = (value: string) => {
    if (!value) return true;

    const isNumericRegex = new RegExp(/^[0-9]+$/);

    if (isNumericRegex.test(value)) return true;

    return getText('rx-number-must-be-numeric');
  };

  const isDuplicatedRx = (value: string) => {
    let isRxDuplicated = prescriptions.some((prx) => prx.rx_number === value);
    isRxDuplicated =
      isRxDuplicated || drugsArray.some((drug) => drug.rxNumber === value);

    return isRxDuplicated ? getText('rx-number-is-duplicated') : true;
  };

  return (
    <InternalScreenContainer
      title={getText('add-meds')}
      buttons={[
        {
          hierarchy: 'tertiary',
          testID: FindPrescriptionFlowTestIDs.doThisLaterButton,
          logger: { id: 'add-prescription' },
          onPress: handleSkip,
          text: getText('do-this-later'),
        },
        {
          hierarchy: 'primary',
          onPress: handleOnPressDone,
          testID: FindPrescriptionFlowTestIDs.doneButton,
          logger: { id: 'add-prescription' },
          text: getText('done'),
        },
      ]}
      scrollToTop={
        !!methods.getFieldState('drugName').error ||
        !!methods.getFieldState('rxNumber').error
      }
    >
      <Form.Alert
        visible={
          !!methods.getFieldState('drugName').error ||
          !!methods.getFieldState('rxNumber').error
        }
        title={getText('unable-to-process-complete-required-fields')}
        intent="error"
      />
      <Text style={styles.textTitle}>
        {getText('adding-prescriptions-for')}
        {patientRecord?.first_name} {patientRecord?.last_name}
      </Text>
      <View>
        <Divider />
      </View>
      <Text style={styles.textTitleSmall}>{getText('prescription-info')}*</Text>
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <TextField
              type="numeric"
              label={getText('rx-number')}
              name="rxNumber"
              handleOpenScanner={handleOpenScanner}
              hideRequiredAsterisk={true}
              onSubmit={methods.handleSubmit(handlePrescription)}
              rules={{
                required: getText('rx-number-is-required'),
                validate: {
                  duplicate: isDuplicatedRx,
                  number: isNumber,
                },
              }}
              testID={FindPrescriptionFlowTestIDs.rxNumberTextField}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <AddMedicationsTypeahead
              name={'drugName'}
              drugNameFieldProps={drugNameFieldProps}
              rules={{
                required: getText('drug-name-is-required'),
              }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column style={styles.addPrescriptionButton}>
            <Form.Actions>
              <Button
                hierarchy="secondary-gray"
                onPress={methods.handleSubmit(handlePrescription)}
                logger={{ id: 'add-prescription' }}
                testID={FindPrescriptionFlowTestIDs.addPrescriptionButton}
              >
                {getText('add-to-list')}
              </Button>
            </Form.Actions>
          </Form.Column>
        </Form.Row>
        <Form.Alert
          title={errors.join('\n')}
          intent="error"
          visible={!!errors.length}
        ></Form.Alert>
      </Form>
      {drugsArray.length > 0 && (
        <>
          <Text style={styles.textTitle}>{getText('your-prescriptions')}</Text>
          <View style={{ marginBottom: theme.getSpacing(0.5) }}>
            <Divider />
          </View>
        </>
      )}
      <ScrollView>
        {drugsArray.map((item, index) => (
          <View key={index}>
            <View style={styles.rowSpaceBetween}>
              <View style={{ flexDirection: 'row', flex: 1 }}>
                <View style={{ width: '90%' }}>
                  <View style={styles.row}>
                    <Text style={styles.textTitleCard}>{item.drugName}</Text>
                  </View>
                  <Text style={styles.textSubtitleCardSmall}>
                    {getText('entered-prescription-manually')}
                  </Text>
                  <Text style={styles.textSubtitleCard}>
                    Rx: {item.rxNumber}
                  </Text>
                  <Text style={styles.textSubtitleCard}>
                    {patientRecord?.first_name} {patientRecord?.last_name}{' '}
                    {patientRecord?.date_of_birth &&
                      formatDate(patientRecord.date_of_birth)}
                  </Text>
                  <View style={{ marginVertical: theme.getSpacing(1) }}>
                    <Divider />
                  </View>
                </View>
                <View>
                  <IconButton
                    icon={TrashWithStripesIcon}
                    color={theme.palette.gray[500]}
                    onPress={() => handleDelete(item)}
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                    logger={{ id: 'deleteIcon' }}
                    size={15}
                    testID={FindPrescriptionFlowTestIDs.trashIconButton}
                  />
                </View>
              </View>
            </View>
          </View>
        ))}
      </ScrollView>
      <View style={{ margin: theme.getSpacing(1) }}>
        <Barcode
          onScan={handleScan}
          isVisible={isScannerVisible}
          onClose={handleScannerClose}
        />
      </View>
    </InternalScreenContainer>
  );
};

export type AddMedicationsManualProps = StackHeaderProps;

interface PrescriptionType {
  drugName: TypeaheadBaseItem[];
  rxNumber: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginBottom: theme.getSpacing(0.5),
  },
  textTitleSmall: {
    color: theme.palette.gray[700],
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    marginVertical: theme.getSpacing(1),
  },
  textTitleCard: {
    color: theme.palette.gray[700],
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 20,
  },
  textSubtitleCard: {
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
  },
  textSubtitleCardSmall: {
    color: theme.palette.gray[500],
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 18,
  },
  addPrescriptionButton: {
    width: '50%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: theme.getSpacing(1),
  },
  row: {
    marginTop: theme.getSpacing(1),
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
