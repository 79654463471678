import { StackHeaderProps } from '@react-navigation/stack';
import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text';
import { PaperlessEnrollmentIcon } from 'assets/icons';
import { ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  FlatList,
  ImageBackground,
  View,
  useWindowDimensions,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MedicationInfo } from './MedicationInfo';
import { Surface } from 'react-native-paper';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { Icon } from 'assets/components/icon';

export const PaperlessEnrollment: FunctionComponent<
  PaperlessEnrollmentProps
> = ({ navigation }) => {
  const sheetRef = React.useRef<BaseModalHandler>(null);
  const { height } = useWindowDimensions();
  const styles = useStyles();
  const theme = useTheme();

  const benefitsList = [
    getText('paperless-enrollment-first-benefit'),
    getText('paperless-enrollment-second-benefit'),
    getText('paperless-enrollment-third-benefit'),
    getText('paperless-enrollment-fourth-benefit'),
  ];

  const handleSubmit = () => {
    navigation.navigate('add-medications-onboarding');
  };

  const handleMedInfoOnPress = () => {
    sheetRef.current?.show();
  };

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.hide();
  };

  return (
    <>
      <ScreenContainer useBottomInset>
        <View style={styles.container}>
          <Text style={styles.textTitle}>
            {getText('paperless-enrollment-title')}
          </Text>
        </View>
        <View style={styles.cardContainer}>
          <Text style={styles.textSubtitle}>
            {getText('paperless-enrollment-subtitle')}
          </Text>
          <Text style={styles.textSubtitle}>
            {getText('paperless-enrollment-text')}
          </Text>
          <InfoCard>
            <View style={styles.cardView}>
              <View style={styles.benefitsIcon}>
                <View style={styles.textContainer}>
                  <Text style={styles.benefitsTitle}>
                    {getText('paperless-enrollment-benefits-title')}
                  </Text>
                  <FlatList
                    data={benefitsList}
                    renderItem={({ item, index }) => (
                      <View style={styles.itemContainer} key={index}>
                        <Text style={styles.itemNumber}>{index + 1}.</Text>
                        <Text style={styles.benefitsListText}>{item}</Text>
                      </View>
                    )}
                    keyExtractor={(item, index) => index.toString()}
                  />
                </View>
                <Icon size={80} icon={PaperlessEnrollmentIcon} />
              </View>
              <TouchableOpacity onPress={handleMedInfoOnPress}>
                <Text style={[styles.medInfo]}>
                  {getText('paperless-enrollment-med-info')}
                </Text>
              </TouchableOpacity>
            </View>
          </InfoCard>
        </View>
        <View>
          <Button
            onPress={handleSubmit}
            hierarchy="primary"
            logger={{ id: 'add-person-submit-button' }}
          >
            {getText('next')}
          </Button>
        </View>
      </ScreenContainer>
      <GenericModal
        webModalProps={{ height: height }}
        title={getText('med-info-location')}
        ref={sheetRef}
        buttons={[
          {
            text: getText('close'),
            hierarchy: 'secondary',
            onPress: handleBottomSheetDismiss,
            logger: { id: 'med-info-details-dismiss-button-modal' },
          },
        ]}
      >
        <MedicationInfo />
      </GenericModal>
    </>
  );
};

export type PaperlessEnrollmentProps = StackHeaderProps;

//TODO: Change this to use the InfoCardComponent on shared components file

const InfoCard: FunctionComponent<
  PropsWithChildren<{
    uri?: string;
  }>
> = ({ uri, children }) => {
  const styles = useStyles();
  const innerContent = uri ? (
    <ImageBackground source={{ uri: uri }} resizeMode="cover" />
  ) : (
    children
  );

  return (
    <Surface style={[styles.button, styles.surface]}>{innerContent}</Surface>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.getSpacing(2.5),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  textTitle: {
    marginBottom: theme.getSpacing(1.5),
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
  },
  textSubtitle: {
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  benefitsTitle: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium.fontFamily,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(0.5),
  },
  medInfo: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
    marginTop: theme.getSpacing(1.5),
  },
  cardContainer: {
    gap: theme.getSpacing(2.5),
    alignItems: 'stretch',
    flex: 1,
  },
  cardView: {
    paddingVertical: theme.getSpacing(1.5),
    paddingHorizontal: theme.getSpacing(1),
  },
  benefitsIcon: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'row',
    gap: theme.getSpacing(1),
  },
  button: {
    minWidth: 64,
    borderStyle: 'solid',
  },
  surface: {
    elevation: 2,
    borderRadius: theme.roundness,
    borderColor: theme.palette.gray[200],
    borderWidth: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.yellow[50],
  },
  textContainer: {
    flex: 1,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  itemNumber: {
    textAlign: 'right',
    paddingHorizontal: theme.getSpacing(0.5),
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  benefitsListText: {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  infoTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.getSpacing(1),
  },
  infoTitleText: {
    color: theme.palette.gray[900],
    fontSize: 18,
    lineHeight: 28,
    textAlign: 'center',
    flex: 1,
  },
}));
