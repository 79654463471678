import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const CalendarTwoColorsIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, accentColor, size, testID, strokeWidth }) => {
  const theme = useTheme();

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 70 80"
      fill="none"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M0 0h70v80H0z" />
      <Path
        fill={color || theme.palette.gray[700]}
        d="M46.283 21.583a2.469 2.469 0 0 0 2.47-2.47V8.495a2.47 2.47 0 0 0-4.94 0v4.211h-6.66l-.027 4.445h6.688v1.964a2.469 2.469 0 0 0 2.47 2.47ZM31.72 21.559a2.47 2.47 0 0 0 2.47-2.47V8.469a2.469 2.469 0 1 0-4.94 0v4.236h-6.675l-.022 4.445h6.697v1.939a2.47 2.47 0 0 0 2.47 2.47Z"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        d="M14.672 12.705H9.997A4.204 4.204 0 0 0 5.8 16.903v48.131c0 1.159.472 2.208 1.232 2.969.76.758 1.81 1.23 2.966 1.23h26.931a17.406 17.406 0 0 1-1.138-4.446H10.244V29.251h46.288v16.222a17.41 17.41 0 0 1 4.445 1.499V16.903a4.204 4.204 0 0 0-4.198-4.198h-5.063l-.022 4.445h4.838v9.138H10.244V17.15h4.428v1.964a2.469 2.469 0 1 0 4.94 0V8.494a2.47 2.47 0 0 0-4.94 0v4.211Z"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        fillRule="evenodd"
        d="M53.198 47.377a15.264 15.264 0 0 0-10.827 4.485 15.264 15.264 0 0 0-4.484 10.826c0 4.228 1.713 8.056 4.484 10.827A15.264 15.264 0 0 0 53.198 78c4.228 0 8.056-1.714 10.827-4.485a15.264 15.264 0 0 0 4.485-10.827c0-4.227-1.714-8.055-4.485-10.826a15.264 15.264 0 0 0-10.827-4.485Zm-7.683 7.629a10.833 10.833 0 0 1 7.683-3.184c3 0 5.717 1.218 7.683 3.184a10.823 10.823 0 0 1 3.183 7.682c0 3.001-1.215 5.72-3.183 7.686a10.83 10.83 0 0 1-7.683 3.18c-3 0-5.717-1.214-7.683-3.18a10.839 10.839 0 0 1-3.183-7.685c0-3.001 1.217-5.718 3.183-7.683Z"
        clipRule="evenodd"
      />
      <Path
        fill={accentColor || theme.colors.brandedText}
        d="M36.155 44.66H30.72c-.679 0-1.234.556-1.234 1.235v2.47c0 .679.555 1.234 1.235 1.234h5.435c.68 0 1.235-.555 1.235-1.234v-2.47c0-.68-.556-1.235-1.235-1.235ZM48.506 44.66h-5.431c-.68 0-1.235.556-1.235 1.235v2.47c0 .284.096.543.257.75a17.458 17.458 0 0 1 7.582-3.605 1.24 1.24 0 0 0-1.173-.85ZM23.81 53.563h-5.434c-.679 0-1.234.556-1.234 1.235v2.47c0 .679.555 1.234 1.234 1.234h5.434c.679 0 1.234-.555 1.234-1.235v-2.47c0-.678-.555-1.234-1.234-1.234ZM36.155 53.563H30.72c-.679 0-1.234.556-1.234 1.235v2.47c0 .679.555 1.234 1.235 1.234h5.447a17.503 17.503 0 0 1 1.223-3.405v-.3c0-.678-.556-1.234-1.235-1.234ZM23.691 44.66h-5.196c-.743 0-1.353.61-1.353 1.354v2.232c0 .746.61 1.353 1.353 1.353h5.196c.746 0 1.353-.607 1.353-1.353v-2.233c0-.743-.607-1.353-1.353-1.353ZM51.627 64.262a2.212 2.212 0 0 1-.647-1.697l-3.719-3.717a1.482 1.482 0 1 1 2.094-2.097l3.72 3.72c.61-.035 1.23.18 1.694.647a2.222 2.222 0 0 1-3.141 3.144ZM23.691 36.06h-5.196c-.743 0-1.353.61-1.353 1.354v2.233c0 .745.61 1.353 1.353 1.353h5.196c.746 0 1.353-.608 1.353-1.353v-2.233c0-.743-.607-1.353-1.353-1.353ZM36 36.06h-5.196c-.744 0-1.354.61-1.354 1.354v2.233c0 .746.61 1.353 1.354 1.353H36c.746 0 1.353-.607 1.353-1.353v-2.233c0-.743-.607-1.353-1.353-1.353ZM48.196 36.06H43c-.744 0-1.354.61-1.354 1.354v2.233c0 .746.61 1.353 1.354 1.353h5.196c.746 0 1.353-.607 1.353-1.353v-2.233c0-.743-.607-1.353-1.353-1.353Z"
      />
    </Svg>
  );
};
