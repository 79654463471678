import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const ClipboardTwoColorsIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, accentColor, size, testID, strokeWidth }) => {
  const theme = useTheme();

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 70 80"
      fill="none"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M0 0h70v80H0z" />
      <Path
        fill={color || theme.palette.gray[700]}
        d="M51.061 68H17.597C14.52 68 12 65.504 12 62.467V19.425c0-3.048 2.52-5.534 5.597-5.534h4.236V17.474h-5.789v46.943H52.99V51.363h.034v-33.89h-6.173v-3.582h4.21c3.078 0 5.597 2.486 5.597 5.534v43.041c0 3.038-2.519 5.534-5.597 5.534Zm-3.626-26.782c0 .738-.605 1.335-1.35 1.335H34.65c-.746 0-1.351-.597-1.351-1.335 0-.737.605-1.335 1.35-1.335h11.435c.746 0 1.35.598 1.35 1.335Zm0 11.082c0 .738-.605 1.336-1.35 1.336H34.65c-.746 0-1.351-.598-1.351-1.336 0-.737.605-1.335 1.35-1.335h11.435c.746 0 1.35.598 1.35 1.335Zm0-22.297c0 .737-.605 1.335-1.35 1.335H34.65c-.746 0-1.351-.598-1.351-1.335 0-.738.605-1.335 1.35-1.335h11.435c.746 0 1.35.597 1.35 1.335ZM34.329 6.204c1.89 0 3.426 1.52 3.426 3.388 0 .044-.005.088-.007.133H30.91c-.002-.045-.007-.089-.007-.133 0-1.868 1.537-3.388 3.426-3.388Zm-7.698 12.867h15.396c1.494 0 2.706-1.198 2.706-2.675V12.4c0-1.478-1.212-2.675-2.706-2.675H40.99c.002-.045.007-.089.007-.133C40.996 5.957 38.006 3 34.33 3c-3.677 0-6.667 2.957-6.667 6.592 0 .044.006.088.006.133h-1.037c-1.494 0-2.706 1.197-2.706 2.675v3.996c0 1.477 1.212 2.675 2.706 2.675Z"
      />
      <Path
        fill={accentColor || theme.colors.brandedText}
        d="m27.883 40.154-4.081 3.724-.188.172-.188-.172-1.79-1.633-.188-.172.188-.172.817-.745.188-.172.188.172.784.716 3.076-2.808.189-.171.188.171.817.746.188.171-.188.172Zm-3.124-3.237c-2.642 0-4.784 1.956-4.784 4.368 0 2.412 2.142 4.368 4.784 4.368 2.643 0 4.785-1.956 4.785-4.368 0-2.412-2.142-4.368-4.785-4.368Zm3.124 14.42-4.081 3.724-.188.172-.188-.172-1.79-1.633-.188-.172.188-.172.817-.745.188-.172.188.172.784.716 3.076-2.808.189-.171.188.171.817.746.188.171-.188.172ZM24.759 48.1c-2.642 0-4.784 1.956-4.784 4.368 0 2.412 2.142 4.368 4.784 4.368 2.643 0 4.785-1.956 4.785-4.368 0-2.412-2.142-4.368-4.785-4.368Zm-3.123-17.382.817-.745.188-.172.188.172.784.716 3.076-2.808.189-.171.188.171.817.746.188.172-.188.172-4.081 3.724-.188.172-.188-.172-1.79-1.633-.188-.172.188-.172Zm3.123 3.752c2.643 0 4.785-1.956 4.785-4.368 0-2.412-2.142-4.368-4.785-4.368-2.642 0-4.784 1.956-4.784 4.368 0 2.412 2.142 4.368 4.784 4.368Z"
      />
    </Svg>
  );
};
