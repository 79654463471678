import React, { FunctionComponent, useEffect, useState } from 'react';
import { Platform, Pressable } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { OrderProgressTracker } from './OrderProgressTracker';
import { useOrderRefillStore } from '../medication-orders/order-store';
import orderService, { PatientOrder } from '../medication-orders/order-service';
import { Text } from 'assets/components/text';
import { usePatientRecordState } from '../../account/patient/patient-store';
import { useUserState } from '../../../store/user-store';
import { OrderDto } from '@digitalpharmacist/order-service-client-axios';
import refillService from '../../refill/refill-service';
import patientService from '../../../api/patient-service';
import {
  NavigationProp,
  ParamListBase,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import { ChevronRightIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';

const ORDER_TRACKERS_VISIBLE_ITEMS = 3;

export type OrderProgressTrackerListProps = {
  visibleItems?: number;
};

export const OrderProgressTrackerList: FunctionComponent<
  OrderProgressTrackerListProps
> = ({ visibleItems = ORDER_TRACKERS_VISIBLE_ITEMS }) => {
  const styles = useStyles();
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const locationId = useUserState((s) => s.user?.preferredPharmacyLocationId);
  const { updateOrderRefillData } = useOrderRefillStore();
  const { patientRecord, recordsUnderCare, patientRecordItems } =
    usePatientRecordState();
  const [orders, setOrders] = useState<PatientOrder[]>();

  useEffect(() => {
    setOrders([]);
    loadOrdersInProgress();
  }, [locationId, patientRecord, patientRecordItems.length]);

  useFocusEffect(
    React.useCallback(() => {
      loadOrdersInProgress();
    }, [locationId, patientRecord, patientRecordItems.length]),
  );

  const loadOrdersInProgress = () => {
    if (!locationId || !patientRecord) return;

    void orderService
      .getPatientOrders(locationId, recordsUnderCare, patientRecord, true)
      .then((response) => {
        setOrders(response);
      });
  };

  const handleOpenOrderDetails = async (order: OrderDto) => {
    if (!updateOrderRefillData || !patientRecord) return;

    const refill = await refillService.getOrderRefill(
      order.location_id,
      order.patient_id,
      order.order_id,
    );

    const patient = await patientService.findLocationPatientRecordByPR(
      order.location_id,
      patientRecord,
    );

    updateOrderRefillData({
      orderRefill: refill,
      selectedPatient: patient,
      selectedPatientRecord: patientRecord,
      origin: 'medications',
    });

    return Platform.OS === 'web'
      ? navigation.navigate('app', { screen: 'order-details-web-tab' })
      : navigation.navigate('app', { screen: 'order-details-bottom-tab' });
  };

  const handleOpenAllOrders = () => {
    navigation.navigate('orders', { screen: 'order' });
  };

  if (!orders?.length) return <></>;

  const remainingOrderCount = orders.length - ORDER_TRACKERS_VISIBLE_ITEMS;
  const visibleOrders = orders.slice(0, visibleItems);

  return (
    <>
      {visibleOrders.map((item) => (
        <OrderProgressTracker
          key={item.order.order_id}
          order={item.order}
          onOpenDetailsPress={() => handleOpenOrderDetails(item.order)}
        />
      ))}
      {remainingOrderCount > 0 && (
        <Pressable style={styles.linkContainer} onPress={handleOpenAllOrders}>
          <Text style={styles.link}>
            {getText('order-tracker-see-orders', {
              total: remainingOrderCount,
            })}
          </Text>
          <Icon
            color={theme.palette.primary[600]}
            size={20}
            icon={ChevronRightIcon}
          />
        </Pressable>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {},
  linkContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  link: {
    textAlign: 'right',
    color: theme.palette.primary[600],
    cursor: 'pointer',
  },
}));
