import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const BoxIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, accentColor, size, testID, strokeWidth }) => {
  const theme = useTheme();

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 70 80"
      fill="none"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M0 0h70v80H0z" />
      <Path
        fill={accentColor || theme.colors.brandedText}
        d="M48 14 15 31.667v8.666l1.357.16 2.3 2.516 2.426.491L23 45.305v-9.638L56 18l-8-4Z"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        d="m53.211 43.513 2.276 1.39.846-1.387v8.15H59V41.288l1.236.838 1.496-2.208-4.475-3.032-.884 1.448h-.04v.065l-3.122 5.115ZM61.047 56.335l-16.09 8.51-1.248-2.357 16.091-8.51 1.247 2.357ZM45.706 43.666l.884-1.448 4.475 3.033-1.496 2.207-1.236-.837V57h-2.666v-8.15l-.846 1.386-2.277-1.39 3.123-5.114v-.066h.04Z"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        fillRule="evenodd"
        d="m68.333 61.093-33.41 17.411L1.667 61.091V24.358L34.925 7.506l10.105 5.096-2.89 1.53-7.212-3.638L5.914 25.195l7.594 3.967L12 30v1.382l-7.667-4.004v32.099l29.334 15.359V42.7L26 38.695V37l1.268-.651L35 40.389l29.15-15.156-8.854-4.465 2.86-1.544 10.176 5.132v36.737Zm-32 13.67V42.7l29.334-15.25v32.024L36.332 74.763Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
