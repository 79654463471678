import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Svg, Path, Rect } from 'react-native-svg';
import { IconProps } from './types';

export const FileUploadIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID, strokeWidth }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M13.5 9v2.667A1.334 1.334 0 0 1 12.167 13H2.833A1.334 1.334 0 0 1 1.5 11.667V9m9.333-4.667L7.5 1m0 0L4.167 4.333M7.5 1v8"
      />
    </Svg>
  );
};
