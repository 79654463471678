import { IMessage, User } from 'react-native-gifted-chat';
import { DocumentPickerAsset } from 'expo-document-picker';
import { ImagePickerAsset } from 'expo-image-picker';
import {
  DirectMessagePatientDto,
  AttachmentDto,
  AddAttachmentDto,
  DirectMessageSortDto,
  DirectMessageExtendedResponseDto,
  AuthorType,
  BulkDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { BulkDetails } from '../../../apps/pharmacy/modules/screens/messages/Bulk/types';

export enum BaseFilterValues {
  ALL = 1,
  UNREAD = 2,
  UNREAD_BY_PATIENT = 3,
  TEMPLATES = 4,
}

export enum MultiFilterValues {
  NO_FILTERS = 0,
  INCLUDES_ME = 1,
  CREATED_BY_ME = 2,
  TODAY = 3,
  LAST_SEVEN_DAYS = 4,
}

export enum BulkFilterValues {
  ALL = 'all',
  SENT = 'sent',
  SCHEDULED = 'delayed',
}

export interface TextFilters {
  full_name: string;
  date_of_birth: string;
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export enum StatusFilter {
  All = 'all',
  Sent = 'sent',
  InProgress = 'in_progress',
  Scheduled = 'scheduled',
  Canceled = 'canceled',
  Failed = 'failed',
}

export interface IRecipient {
  bulk_id: string;
  location_patient_id: string;
}

export interface IAuthor {
  firstName: string;
  lastName: string;
}

export interface IBulkMessage
  extends Pick<
    BulkDto,
    | 'id'
    | 'pharmacy_id'
    | 'location_id'
    | 'subject'
    | 'content'
    | 'content_email'
    | 'content_sms'
    | 'recipients'
    | 'schedule_type'
    | 'scheduled_time'
    | 'recipients_number'
    | 'status'
    | 'sent_time'
    | 'created_at'
    | 'updated_at'
    | 'message_type'
  > {
  author: User;
}

export interface BulkFilters {
  status: StatusFilter;
  message_type: BulkMessageType;
}

export interface BulkMessageType {
  [key: string]: boolean;
  secure: boolean;
  notSecure: boolean;
}

export enum BulkMessageTypeKeys {
  Secure = 'secure',
  NotSecure = 'notSecure',
}

export interface IConversationsSorting {
  label: string;
  value: string;
  apiValue: DirectMessageSortDto[];
  order: Order;
  isDate: boolean;
  field: string;
}

export interface ChatState {
  conversationsGroupedByPatient: DirectMessagePatientDto[];
  selectedPatient: LocationPatientRecordDto | null;
  selectedPatientConversations: DirectMessagePatientDto[];
  selectedUserNames: Record<string, string>;
  selectedConversation: DirectMessagePatientDto | null;
  viewedConversationsSet: Set<string>;
  selectedMessages: IMessageExtended[];
  rawConversations: DirectMessagePatientDto[];
  rawConversationsCount: number;
  isOpenNewChatModal: boolean;
  isOpenSidebarNewChatModal: boolean;
  messagesPagination: MessagesPagination;
  showAddPatientModal: boolean;
  showEditModal: boolean;
  showTemplatesModal: boolean;
  patientsWithoutConversationsSet: Set<string>;
  selectedTemplate: ITemplate | null;

  disabled: boolean;
  count: {
    unread?: number;
  };

  chatBoxText: string;
  failedMessagesInConversation: Record<string, IMessageExtended[]>;
  statusInConversation: Record<string, MessageStatus> | null;
}

export interface BulkMessagesState {
  bulks: IBulkMessage[];
  bulksTotalCount: number;
  isNewBulkMessageModalOpen: boolean;
  filters: BulkFilters;
  bulkDetails: BulkDetails;
}

export interface ConversationsManipulationState {
  pagination: ConversationPatientsPagination;
  baseFilter: BaseFilterValues;
  multiFilters: MultiFilterValues[];
  textFilters: TextFilters;
  sorting: IConversationsSorting;
}

export interface EmittedMessage {
  pharmacy_id: string;
  location_id: string;
  location_patient_id: string;
  conversation_id: string;
  author_type: AuthorType;
}

export interface EmittedNewConversation {
  location_id: string;
  location_patient_id: string;
  conversation_id: string;
  author_type: AuthorType;
}

export interface TypedMessage {
  text: string;
  user: User;
  attachments: DocumentPickerAsset[];
  retryMessageId?: string;
}

export interface RetryContent {
  text: string;
  id: string;
  attachments: DocumentPickerAsset[];
}

export interface IMessageExtended extends IMessage {
  attachments: IComposedAttachments;
  author_type: string;
}

export interface IComposedAttachments {
  // TODO: fix this later. Bad experience to have so many types for 1 variable
  // AttachmentDto - attachments that is fetched from api
  // AttachmentExtendedDto - same as AttachmentDto, but with connected message_id. In some places we have it, in some - we do not
  // DocumentPickerAsset - temporary image(s) on frontend before saving it on backend on pharmacy and patient side (to show 'sending' in Bubble)
  files: (AttachmentDto | AttachmentExtendedDto | DocumentPickerAsset)[];
  // Image - same as AttachmentDto, but with couple more properties that are added on frontend after fetching from api
  // DocumentPickerAsset - temporary image(s) on frontend before saving it on backend on pharmacy side (to show 'sending' in Bubble)
  // ImagePickerAsset - temporary image(s) on frontend before saving it on backend on patient side (to show 'sending' in Bubble)
  images: (Image | DocumentPickerAsset | ImagePickerAsset)[];
}

// Extended because AttachmentDto does not have `message_id`, but in fact attachments have this property.
// We do not update AttachmentDto, because we need to add this explicitly to Attachment entity.
// This will drop and recreate message_id, which will cause a loss of data.
export interface AttachmentExtendedDto extends AttachmentDto {
  message_id: string;
}

export interface IUploadFilesResult {
  isError: boolean;
  filesData: AddAttachmentDto[];
}

export interface Image extends AttachmentDto {
  url: string;
  noImageUrl: string;
}

export interface MessagesPagination {
  skip: number;
  take: number;
  count: number;
}

export interface ConversationPatientsPagination {
  limit: number;
}

export interface GetMessagesResult {
  messages: IMessageExtended[];
  count: number;
}

export interface EmittedUpdatedUserStatus {
  patient_viewed_all_messages: boolean;
  pharmacy_viewed_all_messages: boolean;
  conversation_id: string;
}

export interface LoadMoreOptions {
  skip: number;
  take: number;
}

export interface IImageModalState {
  show: boolean;
  uri: string;
  stored_filename: string;
}

export interface IDocumentModalState {
  show: boolean;
  stored_filename: string;
  name: string;
}
export interface IFileStorageService {
  isImage(fileName: string): boolean;
  readUrl(
    category: LocationCategory,
    locationId: string,
    fileName: string,
    pharmacyId: string,
  ): Promise<any>;
}

export interface UserTyping {
  id: string;
  name: string;
  conversation_id: string;
  author_type: string;
  location_id: string;
  location_patient_id: string;
}

export interface ITemplate {
  id: number;
  name: string;
  subject: string;
  body: string;
}

export interface IGlobalUnifiedCommsService {
  getAllMessagesByConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    skip?: number,
    take?: number,
  ): Promise<DirectMessageExtendedResponseDto>;
  getConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessagePatientDto>;
}

export enum MessageStatus {
  Sending = 'sending',
  Error = 'error',
  Idle = 'idle',
  Delivered = 'delivered',
}
