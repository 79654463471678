import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { InternalScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text/Text';
import { getText } from 'assets/localization/localization';
import { Platform, useWindowDimensions, View } from 'react-native';
import { Map } from '../../../../../packages/assets/components/map';
import {
  useRefillMedicationsStore,
  useRefillReviewMethodStore,
} from './refill-store';
import { RefillTestIDs } from './RefillTestIDs';
import { useAppStateStore } from '../../store/app-store';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { StackHeaderProps } from '@react-navigation/stack';
import { Divider } from 'react-native-paper';
import { buildMessageList } from '../messages/messages-actions';
import {
  NewMessageHandler,
  SubjectOptionsEnum,
} from '../messages/MessageProps';
import { MessageSquareIcon } from 'assets/icons';
import { NewMessage } from '../messages/NewMessage';
import { UnableToProcess } from '../../components/unable-to-process/UnableToProcess';
import { InternalScreenButtonProps } from 'assets/layout/screen/InternalScreenContainer';

export const RefillConfirmation: FunctionComponent<
  PropsWithChildren<RefillConfirmationProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { stores, is_patient_start_chat_available } = useAppStateStore();
  const { selectedLocationId } = useRefillMedicationsStore();
  const { status, methodMessage } = useRefillReviewMethodStore();
  const { width } = useWindowDimensions();
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();

  const onMessageCreate = async () => {
    await buildMessageList();
  };

  const newMessageRef = React.useRef<NewMessageHandler>(null);

  const mapWidth =
    Platform.OS === 'web'
      ? theme.webMaxWidth - 32
      : width - theme.getSpacing(4);

  const handleOnPressDone = () => {
    navigation.navigate('app', { screen: 'medications' });
  };

  const handleMessagePress = () => {
    newMessageRef.current?.show();
  };

  useEffect(() => {
    const selectedStore = stores.find(
      (store) => store.id === selectedLocationId,
    );

    if (selectedStore) {
      setSelectedStore(selectedStore);
    }
  }, [selectedLocationId]);

  const getScreenButtons = () => {
    const buttons: InternalScreenButtonProps[] = [
      {
        hierarchy: 'secondary',
        onPress: handleOnPressDone,
        testID: RefillTestIDs.refillConfirmationDone,
        logger: { id: 'refill-medication-selection-next' },
        text: getText('done'),
      },
    ];
    if (is_patient_start_chat_available) {
      buttons.push({
        hierarchy: 'tertiary',
        onPress: handleMessagePress,
        testID: RefillTestIDs.messageButton,
        icon: MessageSquareIcon,
        logger: { id: RefillTestIDs.messageButton },
        text: getText('send-a-secure-message'),
      });
    }

    return buttons;
  };

  return (
    <InternalScreenContainer
      title={getText('medications-fill-your-prescription')}
      buttons={status !== 'error' ? getScreenButtons() : undefined}
      style={styles.container}
    >
      {status === 'error' ? (
        <UnableToProcess navigation={navigation} />
      ) : (
        <>
          <View>
            <View style={styles.container}>
              <Text style={styles.textTitle}>{getText('order-submitted')}</Text>
            </View>
            <Divider />
            <View style={styles.container}>
              {selectedStore && (
                <Map
                  useWideGenericImage
                  address={selectedStore.address}
                  width={mapWidth}
                  height={50}
                />
              )}
            </View>
            <View style={{ paddingVertical: theme.getSpacing(1) }}>
              <Text style={styles.textTitle}>{selectedStore?.name}</Text>
              {selectedStore?.address && (
                <>
                  <Text style={styles.textSecondary}>
                    {selectedStore.address.address1}
                  </Text>
                  <Text style={styles.textSecondary}>
                    {selectedStore.address.address2}
                  </Text>
                </>
              )}
            </View>
            <Divider />
            <View style={styles.container}>
              <Text style={styles.textSecondary}>{methodMessage}</Text>
            </View>
          </View>
          <NewMessage
            preSelectedSubject={SubjectOptionsEnum.MedicationInfo}
            ref={newMessageRef}
            onMessageCreate={onMessageCreate}
          />
        </>
      )}
    </InternalScreenContainer>
  );
};

export type RefillConfirmationProps = StackHeaderProps;

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.lumistryFonts.display,
    textAlign: 'center',
    color: theme.palette.gray[900],
  },
  textSecondary: {
    ...theme.lumistryFonts.text.medium,
    textAlign: 'center',
    color: theme.palette.gray[500],
  },
  textInformation: {
    ...theme.lumistryFonts.text.large,
    textAlign: 'center',
    color: theme.palette.gray[700],
    marginTop: theme.getSpacing(0.5),
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    textAlign: 'center',
    justifyContent: 'center',
  },
}));
