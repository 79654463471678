import { useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { MultiColorIconProps } from './types';

export const CovidIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, accentColor, size, testID, strokeWidth }) => {
  const theme = useTheme();

  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 70 80"
      fill="none"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path fill="#fff" d="M0 0h70v80H0z" />
      <Path
        fill={accentColor || theme.colors.brandedText}
        fillRule="evenodd"
        d="M36.173 45.34a3.2 3.2 0 1 1 0 6.4 3.2 3.2 0 0 1 0-6.4Z"
        clipRule="evenodd"
      />
      <Path
        fill={color || theme.palette.gray[700]}
        fillRule="evenodd"
        d="M36.173 41.073a7.465 7.465 0 0 1 7.466 7.467 7.467 7.467 0 1 1-14.935 0 7.467 7.467 0 0 1 7.469-7.467Zm8.105-9.13 2.727-4.847a3.2 3.2 0 1 1 1.857 1.045l-2.727 4.85a18.581 18.581 0 0 1 2.562 1.978l10.02-9.675a3.202 3.202 0 1 1 6.112-1.333 3.202 3.202 0 0 1-4.636 2.863L50.174 36.5a18.381 18.381 0 0 1 4.434 10.973h6.553a3.2 3.2 0 1 1 0 2.132h-6.553a18.385 18.385 0 0 1-4.512 11.064l5.078 4.971a4.267 4.267 0 1 1-1.488 1.519l-5.08-4.968a18.407 18.407 0 0 1-8.215 4.329l.17.972a2.4 2.4 0 1 1-2.1.366l-.168-.974c-.697.078-1.404.12-2.119.12-1.632 0-3.213-.212-4.72-.61l-2.225 6.807a3.2 3.2 0 1 1-2.024-.663l2.225-6.804a18.539 18.539 0 0 1-6.314-4.138 18.466 18.466 0 0 1-2.05-2.44l-8.724 5.408a4.272 4.272 0 0 1-4.072 5.544 4.267 4.267 0 1 1 2.952-7.349l8.72-5.408a18.382 18.382 0 0 1-2.234-8.812c0-.773.047-1.535.142-2.281l-2.072-.38a3.2 3.2 0 1 1 .385-2.093l2.066.38a18.411 18.411 0 0 1 3.677-7.348l-2.448-2.263a2.405 2.405 0 0 1-.86.157 2.4 2.4 0 1 1 0-4.8 2.399 2.399 0 0 1 2.303 3.077l2.451 2.262a18.453 18.453 0 0 1 8.234-4.601l-1.197-6.118A4.269 4.269 0 0 1 30.57 16a4.27 4.27 0 0 1 1.901 8.09l1.205 6.151c.815-.11 1.65-.167 2.496-.167 2.907 0 5.66.673 8.105 1.87Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
