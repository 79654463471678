import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { View, Pressable, Platform } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Logo } from '../../components/logo/Logo';
import { ScreenContainer } from 'assets/layout';
import { GoogleSignInButton } from '../../components/google-sign-in-button/GoogleSignInButton';
import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import { EmailIcon } from 'assets/icons';
import { useAppStateStore } from '../../store/app-store';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { googleLogin } from '../login/login-actions';
import * as Linking from 'expo-linking';
import PharmacyService from '../../api/pharmacy-service';
import {
  PolicyType,
  PharmacyPoliciesDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import {
  LoginStackNavigationProp,
  LoginStackParamList,
} from '../../navigation/LoginNavigation';
import IntroWebHeader from '../../components/landing-header/IntroWebHeader';
import { useCurrentUrl } from 'assets/hooks';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { Text } from 'assets/components/text';

export const Intro: FunctionComponent<PropsWithChildren<IntroProps>> = ({
  navigation,
}) => {
  const { url } = useCurrentUrl();
  const theme = useTheme();
  const styles = useStyles();
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [policies, setPolicies] = useState<PharmacyPoliciesDto>({});
  const [policyModalSettings, setPolicyModalSettings] = useState({
    title: '',
    type: '',
    ref: React.useRef<BottomSheetModal>(null),
    state: false,
  });
  const policyModalSettingsRef = React.useRef<BaseModalHandler>(null);
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const pharmacyName = useAppStateStore((state) => state.pharmacyName);

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    if (url && url !== '') {
      const parsedUrl = url.split('#');
      if (parsedUrl.length > 1) {
        const queryParams = parsedUrl[1];
        const parsedQueryParams = new URLSearchParams(queryParams);
        const idToken = parsedQueryParams.get('id_token');
        const accessToken = parsedQueryParams.get('access_token');
        if (idToken && accessToken) {
          googleLogin(
            idToken,
            accessToken,
            navigation as LoginStackNavigationProp,
          );
        }
      }
    }
  }, [url]);

  useEffect(() => {
    if (pharmacyId) {
      void (async () => {
        const policiesResponse: PharmacyPoliciesDto =
          await PharmacyService.findPoliciesForPharmacy(pharmacyId);
        setPolicies(policiesResponse);
      })();
    }
  }, [pharmacyId]);

  const toggleIsNewPatient = () => {
    setIsNewPatient((prevValue) => !prevValue);
  };

  const continueWithEmail = async () => {
    if (url && url !== '') {
      const { queryParams } = Linking.parse(url);
      if (queryParams) {
        navigation.navigate('login-enter-email', queryParams as never);
      } else {
        navigation.navigate('login-enter-email');
      }
    } else {
      navigation.navigate('login-enter-email');
    }
  };

  const closePolicyModal = () => {
    setPolicyModalSettings((prevState) => ({
      ...prevState,
      type: '',
      state: false,
      title: '',
    }));
    policyModalSettingsRef.current?.hide();
  };

  const openPolicyModal = (policyType: PolicyType) => {
    policyModalSettingsRef.current?.show();
    const policyTitle =
      policyType === PolicyType.TermsOfService
        ? getText('terms-of-service')
        : getText('privacy-policy');
    setPolicyModalSettings((prevState) => ({
      ...prevState,
      type: policyType,
      state: true,
      title: policyTitle,
    }));
  };

  const getPoliciesContent = (): ReactNode => {
    return (
      <>
        <>{getText('continuation-disclaimer')} </>
        <Text
          style={styles.link}
          onPress={() => openPolicyModal(PolicyType.TermsOfService)}
        >
          {getText('terms-of-service')}&nbsp;
        </Text>
        <>{getText('and')} </>
        <Text
          style={styles.link}
          onPress={() => openPolicyModal(PolicyType.PrivacyPolicy)}
        >
          {getText('privacy-policy')}
        </Text>
        .
      </>
    );
  };

  function getModalContent() {
    if (policyModalSettings.type in policies) {
      return policies[policyModalSettings.type as PolicyType]?.content;
    } else {
      return `${getText('contact-pharmacy-for-policy-info')} ${
        policyModalSettings.title
      }`;
    }
  }

  return (
    <View style={[styles.container]}>
      {isWeb && <IntroWebHeader />}
      <ScreenContainer
        useBottomInset={true}
        useTopInset={true}
        backgroundColor={
          isWeb ? theme.palette.gray[50] : theme.colors.brandedPrimary
        }
      >
        <View style={styles.introContainer}>
          <View style={styles.logoWrapper}>
            <Logo width={isWeb ? 340 : 241} isOnDark={!isWeb} />
          </View>
          {isNewPatient && (
            <View>
              <Text style={[styles.text, styles.thankYouText]}>
                {getText('thank-you-for-choosing')} {'\n'} {pharmacyName}!
              </Text>
              <Text style={[styles.text, styles.getStartedText]}>
                {getText('to-get-started-choose')}
              </Text>
            </View>
          )}
          <View style={styles.loginOptionsContainer}>
            <View style={styles.loginButtonsContainer}>
              <GoogleSignInButton
                text={getText('google-continue')}
                style={[styles.marginBottom2]}
              />
              <Button
                icon={EmailIcon}
                style={[styles.button]}
                hierarchy={'secondary-gray'}
                onPress={continueWithEmail}
                logger={{ id: 'email-continue-button' }}
              >
                {getText('email-continue')}
              </Button>
            </View>
            <View>
              <Pressable onPress={toggleIsNewPatient}>
                <Text style={[styles.blueText, styles.marginTop3]}>
                  {isNewPatient
                    ? getText('go-back')
                    : getText('i-am-a-new-patient')}
                </Text>
              </Pressable>
            </View>

            <View style={styles.policiesContainer}>
              <Text style={styles.text}>{getPoliciesContent()}</Text>
            </View>

            <GenericModal
              title={policyModalSettings.title}
              ref={policyModalSettingsRef}
              buttons={
                isWeb
                  ? [
                      {
                        onPress: closePolicyModal,
                        logger: { id: 'policy-ok-button-modal' },
                        text: getText('close'),
                        hierarchy: 'primary',
                      },
                    ]
                  : []
              }
              showDismissButton={!isWeb}
              isScrollable
            >
              <Text style={styles.modalText}>{getModalContent()}</Text>
            </GenericModal>
          </View>
        </View>
      </ScreenContainer>
    </View>
  );
};

type IntroProps = NativeStackScreenProps<LoginStackParamList, 'intro'>;

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor:
      Platform.OS === 'web'
        ? theme.palette.gray[50]
        : theme.colors.brandedPrimary,
  },
  introContainer: {
    flex: Platform.OS === 'web' ? 1 : 15,
    marginTop: Platform.OS === 'web' ? 0 : theme.getSpacing(4),
  },
  marginBottom2: {
    marginBottom: theme.getSpacing(2),
  },
  marginTop1: {
    marginTop: theme.getSpacing(1),
  },
  marginTop3: {
    marginTop: theme.getSpacing(3),
  },
  paddingTop3: {
    paddingTop: theme.getSpacing(3),
  },
  button: {
    borderColor: theme.palette.gray[300],
    paddingTop: theme.getSpacing(0.5),
    paddingBottom: theme.getSpacing(0.5),
  },
  text: {
    ...theme.lumistryFonts.text.small.regular,
    color:
      Platform.OS === 'web'
        ? theme.lumistryFonts.text.small.regular.color
        : theme.palette.white,
    textAlign: 'center',
    paddingHorizontal: Platform.OS === 'web' ? theme.getSpacing(4) : undefined,
    fontSize: 12,
  },
  blueText: {
    color:
      Platform.OS === 'web' ? theme.palette.primary[600] : theme.palette.white,
    textAlign: 'center',
  },
  link: {
    ...theme.lumistryFonts.text.small.bold,
    fontSize: 12,
    textDecorationLine: 'underline',
    color:
      Platform.OS === 'web'
        ? theme.lumistryFonts.text.small.bold.color
        : theme.palette.white,
  },
  thankYouText: {
    ...theme.fonts.medium,
    fontSize: 20,
    lineHeight: 30,
  },
  getStartedText: {
    fontSize: 18,
    lineHeight: 28,
    marginTop: theme.getSpacing(3),
  },
  modalText: {
    lineHeight: 20,
    padding: theme.getSpacing(1),
  },
  loginOptionsContainer: {
    marginTop: Platform.OS === 'web' ? 0 : theme.getSpacing(4),
    flex: 1,
  },
  loginButtonsContainer: {
    width: '100%',
    paddingHorizontal: Platform.OS === 'web' ? theme.getSpacing(3) : undefined,
    alignSelf: 'center',
  },
  logoWrapper: {
    flex: Platform.OS === 'web' ? 0.5 : 2,
    marginTop: Platform.OS === 'web' ? '25%' : 0,
  },
  policiesContainer: { marginTop: Platform.OS === 'web' ? 44 : 55, flex: 1 },
}));
