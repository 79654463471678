import React, { PropsWithChildren, useRef } from 'react';
import { View, Pressable } from 'react-native';
import { BottomSheet } from '../bottom-sheet';
import { makeStyles, useTheme } from '../../theme';
import { ChevronDownIcon, CheckIcon } from '../../icons';
import { Text } from '../text';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  DropDownOption,
  DropdownSelectProps,
  DropdownSelectHandler,
} from './types';
import { GenericBottomSheet } from '../generic-bottom-sheet/GenericBottomSheet';
import { BaseModalHandler } from '../base-modal/BaseModal';
import { Icon } from '../icon';

const DropdownNative: ForwardRefRenderFunction<
  DropdownSelectHandler,
  PropsWithChildren<DropdownSelectProps>
> = (
  {
    label,
    options,
    labelInlined,
    disabled,
    onChange,
    value,
    placeholder,
    testID,
  },
  ref: ForwardedRef<DropdownSelectHandler>,
) => {
  const styles = useStyles();
  const theme = useTheme();
  const sheetRef = useRef<BaseModalHandler>(null);

  const handleShow = () => {
    sheetRef.current?.show();
  };

  const handleHide = () => {
    sheetRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleCloseBottomSheet = (option: DropDownOption) => {
    if (option) {
      onChange?.(option.value);
    }
    handleHide();
  };

  const inputText = options.find((x) => x.value === value)?.label;
  const height =
    options.length > 1 ? (options.length * 8).toString() + '%' : '15%';

  return (
    <>
      {!labelInlined ? (
        <View>
          {label && (
            <Text
              style={styles.notInlineLabel}
              testID={DropdownSelectNativeTestIDs.label}
            >
              {label}
            </Text>
          )}
          <Pressable
            accessibilityRole="button"
            accessibilityLabel={inputText}
            onPress={handleShow}
            disabled={disabled}
            style={[styles.dropdownBase, styles.notInlineHeight]}
          >
            <View style={styles.dropdownBaseContent}>
              <Text
                style={styles.label}
                testID={DropdownSelectNativeTestIDs.placeholder}
              >
                {inputText || placeholder}
              </Text>

              <View style={styles.icon}>
                <Icon
                  size={20}
                  color={theme.palette.gray[500]}
                  icon={ChevronDownIcon}
                />
              </View>
            </View>
          </Pressable>
        </View>
      ) : (
        <Pressable
          testID="dropdown-test-id"
          accessibilityRole="button"
          accessibilityLabel={inputText}
          onPress={handleShow}
          disabled={disabled}
          style={[
            disabled ? styles.dropdownBaseDisabled : styles.dropdownBase,
            styles.inlineHeight,
          ]}
        >
          <View style={styles.dropdownBaseContent}>
            <View style={styles.inlineLabelContainer}>
              {(label || placeholder) && value && (
                <Text
                  testID={DropdownSelectNativeTestIDs.label}
                  style={styles.inlinedLabel}
                >
                  {label || placeholder}
                </Text>
              )}
              <Text
                style={[
                  styles.inlinedLabelText,
                  !value && {
                    color: theme.palette.gray[500],
                  },
                ]}
              >
                {inputText || label}
              </Text>
            </View>
            <View style={styles.inlinedLabelIcon}>
              <Icon
                size={20}
                color={theme.palette.gray[500]}
                icon={ChevronDownIcon}
              />
            </View>
          </View>
        </Pressable>
      )}

      <GenericBottomSheet ref={sheetRef} title={''}>
        {options.map((option, index) => (
          <Pressable
            accessibilityRole="button"
            accessibilityLabel={option.label}
            style={({ pressed }) => [
              {
                backgroundColor: pressed ? theme.palette.gray[25] : undefined,
              },
              styles.options,
            ]}
            key={option.value}
            onPress={() => {
              handleCloseBottomSheet(option);
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text
                testID={DropdownSelectNativeTestIDs.option}
                style={styles.optionsLabel}
              >
                {option.label}
              </Text>
              {value === option.value && (
                <View style={styles.checkIcon}>
                  <Icon size={30} icon={CheckIcon} />
                </View>
              )}
            </View>
          </Pressable>
        ))}
      </GenericBottomSheet>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dropdownBase: {
    borderRadius: theme.roundness,
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    justifyContent: 'center',
  },
  dropdownBaseDisabled: {
    borderRadius: theme.roundness,
    borderWidth: 1,
    backgroundColor: theme.palette.gray[200],
    borderColor: theme.palette.gray[300],
    justifyContent: 'center',
  },
  dropdownBaseContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inlineLabelContainer: { flex: 1, justifyContent: 'center', gap: 4 },
  inlineHeight: {
    height: 58,
  },
  notInlineHeight: {
    height: 44,
  },
  notInlineLabel: {
    ...theme.lumistryFonts.text.medium.regular,
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(1),
  },
  inlinedLabel: {
    marginLeft: theme.getSpacing(1.5),
    ...theme.lumistryFonts.label.xxSmall,
    color: theme.palette.gray[600],
  },
  inlinedLabelText: {
    ...theme.lumistryFonts.label.large,
    marginLeft: theme.getSpacing(1.5),
    color: theme.palette.gray[900],
  },
  label: {
    marginLeft: theme.getSpacing(2),
    fontSize: 16,
    color: theme.palette.gray[500],
    textAlignVertical: 'center',
    flex: 1,
  },
  inlinedLabelIcon: {
    marginRight: theme.getSpacing(1),
    alignSelf: 'center',
  },
  icon: {
    marginRight: theme.getSpacing(1),
    alignSelf: 'center',
  },
  options: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[100],
  },
  optionsLabel: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    color: theme.palette.gray[500],
    fontSize: 16,
    flex: 8,
  },
  checkIcon: {
    flex: 1,
    paddingTop: theme.getSpacing(2),
  },
}));

export const DropdownSelectNativeTestIDs = {
  label: 'dropdown-label',
  placeholder: 'dropdown-placeholder',
  option: 'dropdown-option',
};

export const DropdownSelectNative = forwardRef<
  DropdownSelectHandler,
  DropdownSelectProps
>(DropdownNative);
