import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path } from 'react-native-svg';
import { IconProps, MultiColorIconProps } from './types';
export const DevicesIcon: FunctionComponent<
  PropsWithChildren<MultiColorIconProps>
> = ({ color, size, testID, accentColor }) => (
  <Svg width={size} height={size} color={color} fill="none" testID={testID}>
    <Path
      fill={accentColor}
      fillRule="evenodd"
      d="M45.858 25.302H51l.008 4.387h-5.15v-4.387ZM16.544 8v11.21H8V8h8.544Zm25.65 0v8.518h-.743a4.4 4.4 0 0 0-4.056 2.691H33.65V8h8.544ZM29.493 8v11.21H20.7V8h8.793ZM61 17.017v5.848h-3.419v-5.848H61ZM45.858 32.614h6.514c.7.556 1.114.871 2.03 1.093V37h-8.544v-4.386Z"
      clipRule="evenodd"
    />
    <Path
      fill="#374D58"
      fillRule="evenodd"
      d="M2.899 33.782h34.543v6.24H1.449c-.8 0-1.449-.644-1.449-1.439V1.438C0 .644.649 0 1.45 0h48.31c.801 0 1.45.644 1.45 1.438v15.567h-2.899V2.876H2.9v30.906ZM55.324 13.65h8.227c.797 0 1.449.648 1.449 1.438v14.62c0 .787-.656 1.438-1.45 1.438h-8.226c-.797 0-1.45-.648-1.45-1.438v-14.62c0-.792.651-1.438 1.45-1.438Zm0 14.14h8.226V15.088h-8.226V27.79Zm0-12.702Zm3.147 14.859a.722.722 0 0 1-.725-.72c0-.396.325-.718.725-.718h1.933c.4 0 .724.322.724.718 0 .398-.324.72-.724.72H58.47ZM41.79 19.88h9.445v2.876h-7.996v20.612h14.252v-9.347h2.899v10.785c0 .794-.649 1.438-1.45 1.438H41.79c-.8 0-1.45-.644-1.45-1.438V21.319c0-.794.65-1.438 1.45-1.438ZM13.53 52c-.8 0-1.45-.644-1.45-1.438 0-.794.65-1.438 1.45-1.438h8.21v-6.947h7.73v6.947h8.21c.8 0 1.45.644 1.45 1.438 0 .794-.65 1.438-1.45 1.438H13.53Zm9.176-14.142a.963.963 0 0 1-.966-.959c0-.529.433-.958.966-.958h5.798c.533 0 .966.429.966.958 0 .53-.433.96-.966.96h-5.798Z"
      clipRule="evenodd"
    />
  </Svg>
);
