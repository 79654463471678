import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Pressable, StyleSheet, GestureResponderEvent } from 'react-native';
import { CheckIcon } from '../../icons/CheckIcon';
import { makeStyles, theme } from '../../theme';
import { Icon } from '../icon';

export const CheckboxBase: FunctionComponent<
  PropsWithChildren<CheckboxBaseProps>
> = ({ isChecked = false, disabled, testID, roundIcon, onPress }) => {
  const styles = useStyles();
  const handlePress = (event: GestureResponderEvent) => {
    onPress(event);
  };

  return (
    <Pressable
      style={[
        styles.checkboxBase,
        isChecked && styles.checkboxChecked,
        disabled && styles.checkboxDisabled,
        roundIcon && styles.iconRounded,
      ]}
      disabled={disabled}
      onPress={handlePress}
      testID={testID}
    >
      {isChecked && (
        <Icon
          color={disabled ? theme.palette.gray[300] : theme.palette.white}
          size={18}
          icon={CheckIcon}
        />
      )}
    </Pressable>
  );
};

interface CheckboxBaseProps {
  disabled?: boolean;
  isChecked?: boolean;
  roundIcon?: boolean;
  testID?: string;
  onPress: (event: GestureResponderEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  checkboxBase: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.palette.gray[500],
    backgroundColor: 'transparent',
  },
  checkboxChecked: {
    backgroundColor: theme.palette.primary[600],
    borderColor: theme.palette.primary[600],
  },
  checkboxDisabled: {
    backgroundColor: theme.palette.gray[100],
    borderColor: theme.palette.gray[300],
  },
  iconRounded: {
    borderRadius: 12,
  },
}));
