import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import { Icon } from '../icon';
import {
  InfoIcon,
  AlertTriangleIcon,
  CheckCircleIcon,
  BellIcon,
  ClockIcon,
  FlagIcon,
  MessageSquareIcon,
  CheckSquareIcon,
  FiltersLinesIcon,
} from '../../icons';
import { makeStyles, useTheme } from '../../theme';
import { Text } from '../text';
import { IconProps } from '../../icons/types';
import { Pressable } from 'react-native';

export const Alert: FunctionComponent<PropsWithChildren<AlertProps>> = ({
  intent,
  title,
  description,
  iconColor,
  descriptionColor,
  titleColor,
  style,
  testID,
  flat,
  size = 16,
  linkText,
  onLinkPress,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const colors = useColors();

  const intentColors = colors[intent];

  const alertIconMap: Record<
    AlertIntent,
    React.FunctionComponent<React.PropsWithChildren<IconProps>>
  > = {
    info: InfoIcon,
    error: AlertTriangleIcon,
    success: CheckCircleIcon,
    warning: AlertTriangleIcon,
    reminder: BellIcon,
    time: ClockIcon,
    notice: FlagIcon,
    selection: CheckSquareIcon,
    message: MessageSquareIcon,
    filter: FiltersLinesIcon,
  };

  const getIcon = (intent: AlertIntent) => {
    return alertIconMap[intent];
  };

  return (
    <View
      style={[
        styles.root,
        !flat && {
          borderColor: intentColors.border,
          backgroundColor: intentColors.background,
          borderWidth: 1,
          borderRadius: theme.roundness,
          padding: theme.getSpacing(2),
        },
        style,
      ]}
      testID={testID}
    >
      <View>
        <Icon
          icon={getIcon(intent)}
          color={iconColor ?? intentColors.emphasis}
          size={size}
          testID={AlertTestIDs.icon}
        />
      </View>
      <View style={styles.textContainer}>
        <View>
          <Text
            style={{
              color:
                titleColor ??
                (intent === 'error' || intent === 'info' || intent === 'success'
                  ? intentColors.emphasis
                  : theme.palette.gray[900]),
              fontSize: 14,
              fontWeight: '500',
            }}
            testID={AlertTestIDs.titleText}
          >
            {title}

            {linkText && (
              <Pressable onPress={() => onLinkPress}>
                <Text
                  style={{
                    color: theme.palette.primary[500],
                    fontSize: 14,
                    fontWeight: '500',
                  }}
                  testID={AlertTestIDs.titleText}
                >
                  {linkText}
                </Text>
              </Pressable>
            )}
          </Text>
        </View>
        {description && (
          <View style={{ marginTop: theme.getSpacing(0.5) }}>
            <Text
              style={{
                color: descriptionColor ?? intentColors.description,
                fontSize: 14,
              }}
              testID={AlertTestIDs.descriptionText}
            >
              {description}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

const useColors = () => {
  const theme = useTheme();
  const warningStyleTheme = {
    border: theme.palette.yellow[300],
    background: theme.palette.warning[50],
    emphasis: theme.palette.yellow[500],
    description: theme.palette.gray[600],
  };
  const noticeStyleTheme = {
    border: theme.palette.warning[300],
    background: theme.palette.warning[25],
    emphasis: theme.palette.gray[400],
    description: theme.palette.gray[900],
  };
  return {
    error: {
      border: theme.palette.error[100],
      background: theme.palette.error[50],
      emphasis: theme.palette.error[700],
      description: theme.palette.error[700],
    },
    info: {
      border: theme.palette.primary[300],
      background: theme.palette.primary[25],
      emphasis: theme.palette.primary[700],
      description: theme.palette.primary[600],
    },
    success: {
      border: theme.palette.success[300],
      background: theme.palette.success[25],
      emphasis: theme.palette.success[700],
      description: theme.palette.success[600],
    },
    warning: warningStyleTheme,
    reminder: warningStyleTheme,
    time: warningStyleTheme,
    notice: noticeStyleTheme,
    selection: noticeStyleTheme,
    message: noticeStyleTheme,
    filter: noticeStyleTheme,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
  },
  textContainer: {
    flexDirection: 'column',
    marginLeft: theme.getSpacing(1),
    flexWrap: 'wrap',
    flex: 1,
    flexGrow: 1,
  },
}));

export interface AlertProps {
  intent: AlertIntent;
  titleColor?: string;
  descriptionColor?: string;
  iconColor?: string;
  title: string;
  description?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  flat?: boolean;
  size?: number;
  linkText?: string;
  onLinkPress?: () => void;
}

export const AlertTestIDs = {
  descriptionText: 'description',
  titleText: 'title',
  icon: 'icon',
};

type AlertIntent =
  | 'info'
  | 'warning'
  | 'error'
  | 'success'
  | 'reminder'
  | 'time'
  | 'notice'
  | 'selection'
  | 'message'
  | 'filter';
