import moment from 'moment';
import {
  IComposedAttachments,
  Order,
  IFileStorageService,
  GetMessagesResult,
  IMessageExtended,
  AttachmentExtendedDto,
  Image,
  IGlobalUnifiedCommsService,
} from '../types/messageTypes';
import {
  AttachmentDto,
  DirectMessagePatientDto,
  DirectMessageDto,
  AuthorType,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import {
  ATTACHMENT_HEIGHT,
  ATTACHMENT_SIDE_PADDING,
  BUTTON_MARGIN,
  DEFAULT_NEW_LINES_COUNT,
  INPUT_TOOLBAR_MARGIN,
  LINE_HEIGHT,
  MAX_LINES_COUNT,
  MESSAGES_CONTAINER_HEIGHT,
  NEW_LINE_CHARACTER,
  TEXT_INPUT_TOOLBAR_HEIGHT,
} from '../../../apps/pharmacy/modules/screens/messages/data';
import { Dimensions } from 'react-native';
const noImage = require('../images/no_image.png');
import { Linking } from 'react-native';
import { SettingsIcon } from 'assets/icons';
import { Avatar as PaperAvatar } from 'react-native-paper';
import theme from '../theme';
import { Avatar } from '../components/avatar';
import { prettyFormat } from '@digitalpharmacist/validation-dp';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Icon } from '../components/icon';

export function compare(
  currentItem: any,
  nextItem: any,
  key: string,
  order: Order = Order.DESC,
  isDate = true,
) {
  let aCompared = isDate
    ? new Date(currentItem[key]).getTime()
    : currentItem[key];
  let bCompared = isDate ? new Date(nextItem[key]).getTime() : nextItem[key];
  aCompared =
    typeof aCompared === 'string' ? aCompared.toLowerCase() : aCompared;
  bCompared =
    typeof bCompared === 'string' ? bCompared.toLowerCase() : bCompared;
  const isDesc = order === Order.DESC;
  if (isDate) {
    const direction = isDesc ? bCompared - aCompared : aCompared - bCompared;
    return direction;
  } else {
    const direction = isDesc ? [-1, 1, 0] : [1, -1, 0];
    if (aCompared > bCompared) {
      return direction[0];
    } else if (aCompared < bCompared) {
      return direction[1];
    } else {
      return direction[2];
    }
  }
}

export function getFullName(user: any): string {
  const { first_name, last_name, firstName, lastName, full_name } = user ?? {};
  if (full_name) {
    return full_name;
  } else if (first_name || last_name) {
    return first_name + ' ' + last_name;
  } else if (firstName || lastName) {
    return firstName + ' ' + lastName;
  } else {
    return '';
  }
}

// Returns example: John D.
export function getFullNameInitials(input: {
  firstName?: string;
  lastName?: string;
  fullName?: string;
}): string {
  const { firstName, lastName, fullName } = input;
  if (fullName) {
    const nameParts = fullName.split(' ');
    const first_name = nameParts[0];
    const last_name = nameParts[nameParts.length - 1];
    return `${first_name} ${last_name.slice(0, 1)}.`;
  }

  // in case we have name like `Mario le Cavalli`
  if ((lastName ?? '').includes(' ')) {
    // `!` here because of checking above
    const nameParts = lastName!.split(' ');
    const lastNameLastPart = nameParts[parseInt.length - 1];
    `${firstName} ${lastNameLastPart[0].slice(0, 1)}.`;
  }

  return `${firstName} ${(lastName ?? '').slice(0, 1)}.`;
}

// These rules apply strictly for past time
export const formatMessageDate = (
  inputDate: string,
  inputFormat?: string | undefined,
): string => {
  const localInputTime = moment.utc(inputDate, inputFormat).local();
  const localBaseTime = moment.utc().local();

  const diff = localInputTime.diff(localBaseTime, 'days');

  const inputYear = localInputTime.year();
  const baseYear = localBaseTime.year();

  if (diff === 0) {
    // 3:50pm (if today)
    const midnight = localBaseTime.clone().startOf('day');
    const min = moment.min(midnight, localInputTime);

    const isInputTimeBeforeMidnight = midnight.diff(min) === 0 ? false : true;

    return isInputTimeBeforeMidnight ? '1d' : localInputTime.format('h:mm a');
  } else if (diff > -8) {
    // 4d (if within the last week)
    return Math.abs(diff) + 'd';
  } else if (inputYear === baseYear) {
    // Dec 2, 10:06am (if this year, but not this week)
    return localInputTime.format('MMM D, h:mm a');
  } else {
    // 11/18/2021 10:43am (if not this year)
    return localInputTime.format('MM/DD/YYYY h:mm a');
  }
};

export const groupConversationsByPatient = (
  rawConversationsData: DirectMessagePatientDto[],
): DirectMessagePatientDto[] => {
  const patientGroupings: Record<string, DirectMessagePatientDto> = {};
  const finalPatientsList: DirectMessagePatientDto[] = [];
  rawConversationsData.forEach((conversation) => {
    const existingConversation =
      patientGroupings[conversation.location_patient_id];

    if (existingConversation) {
      const existingDate = new Date(
        existingConversation.most_recent_qualifying_message_date,
      );
      const conversationDate = new Date(
        conversation.most_recent_qualifying_message_date,
      );

      if (existingDate < conversationDate) {
        existingConversation.most_recent_qualifying_message =
          conversation.most_recent_qualifying_message;
        existingConversation.most_recent_qualifying_message_date =
          conversation.most_recent_qualifying_message_date;
      }

      if (!conversation.pharmacy_viewed_all_messages) {
        existingConversation.pharmacy_viewed_all_messages = false;
      }
    } else {
      patientGroupings[conversation.location_patient_id] = { ...conversation };
    }
  });

  for (const [key, value] of Object.entries(patientGroupings)) {
    finalPatientsList.push(value);
  }

  return finalPatientsList;
};

export async function composeAttachments(
  attachments: AttachmentDto[] | undefined,
  locationId: string,
  pharmacyId: string,
  FileStorageService: IFileStorageService,
): Promise<IComposedAttachments> {
  const attachmentsPresent = Boolean(attachments && attachments.length);

  if (!attachmentsPresent) {
    return {
      files: [],
      images: [],
    };
  }

  const [images, files] = await separateImages<AttachmentDto>(
    attachments!,
    FileStorageService,
  );
  let urlImages: any = [];
  urlImages = images.length
    ? await Promise.all(
        images.map((image) =>
          getAttachmentUrl({
            attachment: image,
            FileStorageService: FileStorageService,
            pharmacyId: pharmacyId,
            locationId: locationId,
          }),
        ),
      )
    : [];

  return {
    files: files,
    images: urlImages,
  };
}

interface PlainAttachment {
  name: string;
}
// This function separates images from simple files.
// Further (in ChatBox component) they will be handled and showed a bit differently.
export async function separateImages<T extends PlainAttachment>(
  files: T[],
  FileStorageService: IFileStorageService,
): Promise<T[][]> {
  return files.reduce(
    (previousElement: T[][], element: T) => {
      const [images, files] = previousElement;
      return FileStorageService.isImage(element.name)
        ? [[...images, element], files]
        : [images, [...files, element]];
    },
    [[], []],
  );
}

// image url we take from our file-storage-service are valid for ~ 5 mins,
// so this should assure that we load image earlier than it expires
export const IMAGE_VALID_PERIOD = 1000 * 60 * 4; // 4 minutes
interface LoadedImagesMapValue {
  loadedTime: Date;
  attachment: Image;
}
const loadedImagesMap: Record<string, LoadedImagesMapValue> = {};
export async function getAttachmentUrl(options: {
  attachment: AttachmentDto;
  FileStorageService: IFileStorageService;
  pharmacyId: string;
  locationId: string;
}): Promise<Image> {
  const { attachment, FileStorageService, locationId, pharmacyId } = options;
  let urlResponse;

  const currentTime = new Date();
  // if the image is loaded for the first time, it will always have load date Jan 01 1970
  // otherwise we get image load date and calculate its valid time (+ IMAGE_VALID_PERIOD)
  // image url we take from our file-storage-service are valid for ~ 5 mins,
  // so this should assure that we load image earlier than it expires
  const imageValidTime =
    attachment.id in loadedImagesMap
      ? new Date(
          loadedImagesMap[attachment.id].loadedTime.getTime() +
            IMAGE_VALID_PERIOD,
        )
      : new Date(0);

  if (currentTime < imageValidTime) {
    return loadedImagesMap[attachment.id].attachment;
  }

  try {
    urlResponse = await FileStorageService.readUrl(
      LocationCategory.DirectMessage,
      locationId,
      attachment.stored_filename,
      pharmacyId,
    );
  } catch (err) {
    console.error(err);
    urlResponse = {
      data: {
        url: '',
      },
    };
  }

  const updatedAttachment = {
    ...attachment,
    url: urlResponse.data.url,
    noImageUrl: noImage,
  };

  loadedImagesMap[updatedAttachment.id] = {
    loadedTime: new Date(),
    attachment: updatedAttachment,
  };

  return updatedAttachment;
}

export function getLinesCount(inputText: string) {
  return (
    (inputText.match(new RegExp(NEW_LINE_CHARACTER, 'g')) || []).length +
    DEFAULT_NEW_LINES_COUNT
  );
}

export function getChatSizes(inputText: string, attachmentsCount: number) {
  const strippedText = stripRichTextEditorElements(inputText);

  const linesCount: number =
    (strippedText.match(new RegExp(NEW_LINE_CHARACTER, 'g')) || []).length +
    DEFAULT_NEW_LINES_COUNT;
  // if attachments exist, multiply count of attachments to height of single one
  // `ATTACHMENT_SIDE_PADDING * 2` - plus padding top, and plus padding bottom
  const attachmentsHeight = attachmentsCount
    ? attachmentsCount * ATTACHMENT_HEIGHT + ATTACHMENT_SIDE_PADDING * 2
    : 0;
  const actionsHeight = 90 + BUTTON_MARGIN; // 32 - default height of button
  const constantHeights =
    attachmentsHeight + actionsHeight + INPUT_TOOLBAR_MARGIN;

  if (linesCount >= MAX_LINES_COUNT) {
    const messagesContainerHeight =
      MESSAGES_CONTAINER_HEIGHT - MAX_LINES_COUNT * LINE_HEIGHT;
    const textInputToolbarHeight =
      TEXT_INPUT_TOOLBAR_HEIGHT + MAX_LINES_COUNT * LINE_HEIGHT;
    return {
      wholeContainerHeight:
        messagesContainerHeight + textInputToolbarHeight + constantHeights,
      messagesContainerHeight: messagesContainerHeight,
      textInputToolbarHeight: textInputToolbarHeight,
    };
  } else {
    const messagesContainerHeight =
      MESSAGES_CONTAINER_HEIGHT - linesCount * LINE_HEIGHT;
    const textInputToolbarHeight =
      TEXT_INPUT_TOOLBAR_HEIGHT + linesCount * LINE_HEIGHT;
    return {
      wholeContainerHeight:
        messagesContainerHeight + textInputToolbarHeight + constantHeights,
      messagesContainerHeight: messagesContainerHeight,
      textInputToolbarHeight: textInputToolbarHeight,
    };
  }
}

export function getPatientsWithoutConversationsSet(
  conversations: DirectMessagePatientDto[],
): Set<string> {
  // patients with empty conversation_id do not have conversations
  return new Set(
    conversations
      .filter((conversation) => !conversation.conversation_id)
      .map((conversation) => conversation.location_patient_id),
  );
}

export function getDynamicHeightForMessages(defaultHeight: number) {
  const defaultScreenHeight = 869; // using Dimensions.get('window').height for 16-inch screen with fully opened browser
  const currentScreenHeight = Dimensions.get('window').height;
  const heightDifference = currentScreenHeight - defaultScreenHeight;
  return defaultHeight + heightDifference;
}

export function stripRichTextEditorElements(text: string) {
  const html = new DOMParser().parseFromString(text, 'text/html');

  html.querySelectorAll('.pill')?.forEach((element) => {
    element.replaceWith(
      '${' +
        element.textContent?.replace(/\uFEFF/g, '').replaceAll(' ', '_') +
        '}',
    );
  });

  html.querySelectorAll('a')?.forEach((element) => {
    element.replaceWith(`(a href=${element.href} text=${element.textContent})`);
  });

  html.querySelectorAll('p')?.forEach((element) => {
    const textContent = element.innerHTML
      .replace(/&nbsp;/g, ' ')
      .replace(/<br\s*\/?>/g, '\n')
      .replace(/&amp;/g, '&');
    element.replaceWith(document.createTextNode(textContent + '\n'));
  });
  html.querySelectorAll('br')?.forEach((element) => {
    element.replaceWith('\n');
  });

  let textContent = html.body.innerText;

  textContent = textContent
    .replace(/^\s+/g, '') // removing first spaces
    .replace(/\s+$/g, (match) => match.replace(/\n$/, '')) // removing `new lines` sign at the end, leaving last spaces
    .replace(/\n/g, '\n') // leaving `new lines` signs
    .replace(/\n\n/g, '\n') // leaving `new lines` signs
    .replace(/\u200B/g, ''); // removing Zero Width Space

  return textContent;
}

export function convertPillToCurlyBraces(text: string) {
  const html = new DOMParser().parseFromString(text, 'text/html');

  html.querySelectorAll('.pill')?.forEach((element) => {
    element.replaceWith(
      '${' +
        element.textContent?.replace(/\uFEFF/g, '').replaceAll(' ', '_') +
        '}',
    );
  });

  return html.documentElement.innerHTML;
}

const SCHEME_URL_PATTERN = /.+:\/\//i;
export const onUrlPress = async (url: string) => {
  try {
    // To handle url without a scheme.
    if (SCHEME_URL_PATTERN.test(url)) {
      await Linking.openURL(url);
    } else {
      await Linking.openURL(`https://${url}`);
    }
  } catch (error) {
    //TODO: review console.error
    console.error(`No handler for URL: ${url}`, error);
  }
};

export const onHyperLinkPress = async (hyperLinkString: string) => {
  if (/href=/gi.test(hyperLinkString)) {
    const url = hyperLinkString.split('href=')[1].split(' ')[0];
    await onUrlPress(url);
  } else {
    console.error('Hyper link is invalid! Hyper link: ', hyperLinkString);
  }
};

export const renderHyperlink = (fullString: string) => {
  let text = '';

  if (/text=/gi.test(fullString)) {
    text = fullString.split('text=')[1].split(')')[0];
  }

  return text;
};

export const renderHyperlinkRegex: RegExp = /\(a[^)]*\)/gi;

export async function getMessages(options: {
  pharmacyId: string;
  locationId: string;
  locationPatientId: string;
  conversationId: string;
  UnifiedCommsService: IGlobalUnifiedCommsService;
  FileStorageService: IFileStorageService;
  failedMessagesInConversation: Record<string, IMessageExtended[]>;
  skip?: number;
  take?: number;
  isPharmacyApp?: boolean;
}): Promise<GetMessagesResult> {
  // if no passed isPharmacyApp option, then default is `true`
  options.isPharmacyApp =
    'isPharmacyApp' in options ? options.isPharmacyApp : true;
  const {
    pharmacyId,
    locationId,
    locationPatientId,
    conversationId,
    UnifiedCommsService,
    FileStorageService,
    skip,
    take,
    failedMessagesInConversation,
    isPharmacyApp,
  } = options;
  let IMessages: IMessageExtended[] = [];

  const data = await Promise.all([
    UnifiedCommsService.getAllMessagesByConversation(
      locationId,
      locationPatientId,
      conversationId,
      skip,
      take,
    ),
    UnifiedCommsService.getConversation(
      locationId,
      locationPatientId,
      conversationId,
    ),
  ]);

  const messages = data[0].messages as DirectMessageDto[];
  const messagesCount: number = data[0].count;
  const conversation: DirectMessagePatientDto = data[1];
  const authors = conversation.all_authors
    ? conversation.all_authors
        .filter((author) => Boolean(author))
        .reduce((accumulator: any, author: any) => {
          return {
            ...accumulator,
            [author._id]: {
              firstName:
                author.first_name[isPharmacyApp ? 'location' : 'pharmacy'],
              lastName:
                author.last_name[isPharmacyApp ? 'location' : 'pharmacy'],
            },
          };
        }, {})
    : {};

  const attachmentsMapByMessageId = await composeAttachmentsMapForMessages({
    messages,
    FileStorageService,
    pharmacyId,
    locationId,
  });

  for (const message of messages) {
    const companionFirstName: string = authors[message.author_id]?.firstName;
    const companionLastName: string = authors[message.author_id]?.lastName;

    const buildAvatar = () => {
      if (message.author_type === AuthorType.SystemGenerated) {
        return (
          <PaperAvatar.Icon
            size={32}
            icon={() => (
              <Icon
                color={theme.palette.gray[700]}
                size={22}
                icon={SettingsIcon}
              />
            )}
            style={{ backgroundColor: theme.palette.gray[100], marginTop: 20 }}
          />
        );
      } else if (message.author_type === AuthorType.Patient) {
        return (
          <Avatar
            size={32}
            firstName={companionFirstName}
            lastName={companionLastName}
            color={theme.palette.gray[100]}
          />
        );
      } else {
        return (
          <Avatar
            size={32}
            firstName={companionFirstName}
            lastName={companionLastName}
            color={theme.palette.primary[800]}
          />
        );
      }
    };

    IMessages.push({
      _id: message.id,
      user: {
        _id: message.author_id,
        avatar: () => buildAvatar(),
        name: companionFirstName + ' ' + companionLastName,
      },
      text: message.content || '',
      createdAt: new Date(message.created_at),
      attachments:
        message.id in attachmentsMapByMessageId
          ? attachmentsMapByMessageId[message.id]
          : { files: [], images: [] },
      author_type: message.author_type,
      sent: true,
    });
  }

  const uniqueMessageIdsSet = new Set(IMessages.map((message) => message._id));

  if (
    failedMessagesInConversation &&
    Object.keys(failedMessagesInConversation).length &&
    failedMessagesInConversation[conversationId]
  ) {
    const filteredFailedMessages = failedMessagesInConversation[
      conversationId
    ].filter((message) => !uniqueMessageIdsSet.has(message._id));

    IMessages = IMessages.concat(filteredFailedMessages);
    IMessages.sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf();
    });
  }

  return {
    messages: IMessages,
    count: messagesCount,
  };
}

// to get urls from our server for many attachments from messages at once (performance upgrade)
async function composeAttachmentsMapForMessages(options: {
  messages: DirectMessageDto[];
  FileStorageService: IFileStorageService;
  pharmacyId: string;
  locationId: string;
  imageBatchSize?: number;
}): Promise<Record<string, IComposedAttachments>> {
  const {
    messages = [],
    FileStorageService,
    pharmacyId,
    locationId,
    imageBatchSize,
  } = options;
  const attachments: AttachmentExtendedDto[] = [];
  const DEFAULT_IMAGE_BATCH_SIZE = 25;
  const map = {};

  if (!messages.length) {
    return map;
  }

  // add message_id to attachment object
  for (const message of messages) {
    if (message.attachments && message.attachments.length) {
      for (const attachment of message.attachments) {
        if ('message_id' in attachment) {
          attachments.push(attachment as AttachmentExtendedDto);
        } else {
          attachments.push({
            ...attachment,
            message_id: message.id,
          });
        }
      }
    }
  }

  if (!attachments.length) {
    return map;
  }

  const [images, files] = await separateImages<AttachmentExtendedDto>(
    attachments!,
    FileStorageService,
  );

  const filesMap = files.reduce(
    (
      accumulator: Record<string, IComposedAttachments>,
      currentFile: AttachmentExtendedDto,
    ) => {
      if (currentFile.message_id in accumulator) {
        accumulator[currentFile.message_id].files.push(currentFile);
        return accumulator;
      } else {
        accumulator[currentFile.message_id] = {
          files: [currentFile],
          images: [],
        };
        return accumulator;
      }
    },
    {},
  );

  const imagesWithUrl: (Image & { message_id: string })[] = [];

  if (images.length) {
    const step = imageBatchSize ?? DEFAULT_IMAGE_BATCH_SIZE;
    const times = Math.ceil(images.length / step);
    let sliceStart = 0;
    let sliceEnd = step;
    for (let i = 0; i < times; i++) {
      const imagesSlice = images.slice(sliceStart, sliceEnd);
      sliceStart = sliceStart + step;
      sliceEnd = sliceEnd + step;

      const readyImagesWithUrl = await Promise.all(
        imagesSlice.map((image) =>
          getAttachmentUrl({
            attachment: image,
            FileStorageService: FileStorageService,
            pharmacyId: pharmacyId,
            locationId: locationId,
          }),
        ),
      );

      imagesWithUrl.push(
        ...(readyImagesWithUrl as (Image & { message_id: string })[]),
      );
    }
  }

  const resultMap = imagesWithUrl.reduce(
    (
      accumulator: Record<string, IComposedAttachments>,
      currentImage: Image & { message_id: string },
    ) => {
      if (currentImage.message_id in accumulator) {
        accumulator[currentImage.message_id].images.push(currentImage);
        return accumulator;
      } else {
        accumulator[currentImage.message_id] = {
          images: [currentImage],
          files: [],
        };
        return accumulator;
      }
    },
    filesMap,
  );

  return resultMap;
}

export enum DIRECT_MESSAGE_TEMPLATE_VARIABLES {
  FirstName = '${first_name}',
  PatientFirstName = '${patient_first_name}',
  PatientLastName = '${patient_last_name}',
  PharmacyPhoneNumber = '${pharmacy_phone_number}',
  PharmacyName = '${pharmacy_name}',
}

export function populatePillText(
  text: string,
  patient?: LocationPatientRecordDto,
  pharmacy?: PharmacyLocationDto,
): string {
  let stringResult = text;

  const pillOptions = [
    {
      pillText: DIRECT_MESSAGE_TEMPLATE_VARIABLES.FirstName,
      originalPillText: patient?.first_name,
    },
    {
      pillText: DIRECT_MESSAGE_TEMPLATE_VARIABLES.PatientFirstName,
      originalPillText: patient?.first_name,
    },
    {
      pillText: DIRECT_MESSAGE_TEMPLATE_VARIABLES.PatientLastName,
      originalPillText: patient?.last_name,
    },
    {
      pillText: DIRECT_MESSAGE_TEMPLATE_VARIABLES.PharmacyPhoneNumber,
      originalPillText: pharmacy?.phone ? prettyFormat(pharmacy?.phone) : '',
    },
    {
      pillText: DIRECT_MESSAGE_TEMPLATE_VARIABLES.PharmacyName,
      originalPillText: pharmacy?.name || '',
    },
  ];

  for (const pillOption of pillOptions) {
    if (pillOption.originalPillText) {
      stringResult = stringResult.replaceAll(
        pillOption.pillText,
        pillOption.originalPillText,
      );
    }
  }

  return stringResult;
}
