import React, { FunctionComponent, useState } from 'react';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { TouchableOpacity, View, Platform, Animated } from 'react-native';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import {
  AccountNotificationIcon,
  MedicationsNotificationIcon,
  MessagesNotificationIcon,
  ChevronsDownIcon,
  ChevronsUpIcon,
  NotificationTypeIcon,
  XIcon,
  ClockIcon,
  CloseIcon,
  EmailIcon,
  CheckIcon,
  ChevronRightIcon,
} from 'assets/icons';
import { Tag } from 'assets/components/tag';
import { IconButton } from 'assets/components/icon-button';
import { formatDateTimeAsString } from 'assets/utils/dateTime';
import { getText } from 'assets/localization/localization';
import {
  NotificationCategory,
  NotificationType,
  NotificationNavigationScreen,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { IconProps } from 'assets/icons/types';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { RectButton } from 'react-native-gesture-handler';
import { NavigationProp, ParamListBase } from '@react-navigation/native';

export const NotificationListItem: FunctionComponent<
  NotificationListItemProps
> = ({
  type,
  category,
  body,
  timestamp,
  isRead = false,
  hideBorder = false,
  markAsRead,
  markAsUnread,
  markAsHidden,
  notificationId,
  refreshNotifications,
  navigationScreen,
  navigationParams,
  handleNavigationScreen,
  navigation,
  closeMenu,
  closeBottomSheet,
}) => {
  const styles = useStyles();
  const [showMore, setShowMore] = useState(true);
  const theme = useTheme();

  const categoryIconMap: Record<
    NotificationCategory,
    React.FunctionComponent<IconProps>
  > = {
    medications: MedicationsNotificationIcon,
    messages: MessagesNotificationIcon,
    account: AccountNotificationIcon,
  };

  const typeIconColorMap: Record<NotificationType, string> = {
    positive_neutral: theme.palette.success[400],
    time_sensitive: theme.palette.primary[600],
    negative: theme.palette.warning[500],
  };

  const categoryIcon = categoryIconMap[category];
  const typeIconColor = typeIconColorMap[type];

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const isLongMessage = body.length > 60;
  const swipeableRef = React.useRef<Swipeable>(null);

  const handleMarkAsRead = async (notificationId: string) => {
    await markAsRead(notificationId);
    if (Platform.OS !== 'web') {
      swipeableRef.current?.close();
    }
    await refreshNotifications();
  };

  const handleMarkAsUnread = async (notificationId: string) => {
    await markAsUnread(notificationId);
    if (Platform.OS !== 'web') {
      swipeableRef.current?.close();
    }
    await refreshNotifications();
  };

  const handleMarkAsHidden = async (notificationId: string) => {
    await markAsHidden(notificationId);
    if (Platform.OS !== 'web') {
      swipeableRef.current?.close();
    }
    await refreshNotifications();
  };

  const handleNavigation = async (notificationId: string) => {
    if (navigationScreen !== NotificationNavigationScreen.None) {
      await handleNavigationScreen(navigationScreen, navigationParams);

      Platform.OS === 'web'
        ? closeMenu && closeMenu()
        : closeBottomSheet && closeBottomSheet();
    }
    handleMarkAsRead(notificationId);
  };

  const renderRightActions = (progress: unknown, dragX: any) => {
    return (
      <>
        <RectButton
          testID={NotificationListItemTestIDs.markAsHidden}
          onPress={() => {
            handleMarkAsHidden(notificationId);
          }}
          style={styles.hideButton}
        >
          <View style={styles.action}>
            <Icon icon={CloseIcon} size={24} color={theme.palette.white} />
            <Animated.Text style={styles.actionText}>
              {getText('hide')}
            </Animated.Text>
          </View>
        </RectButton>
        <RectButton
          testID={
            isRead
              ? NotificationListItemTestIDs.markAsUnread
              : NotificationListItemTestIDs.markAsRead
          }
          onPress={() => {
            isRead
              ? handleMarkAsUnread(notificationId)
              : handleMarkAsRead(notificationId);
          }}
          style={styles.readButton}
        >
          <View style={styles.action}>
            {!isRead ? (
              <Icon icon={CheckIcon} size={24} color={theme.palette.white} />
            ) : (
              <Icon
                icon={EmailIcon}
                size={24}
                color={theme.palette.white}
                strokeWidth={1.5}
              />
            )}
            <Animated.Text style={styles.actionText}>
              {isRead ? getText('mark-as-unread') : getText('mark-as-read')}
            </Animated.Text>
          </View>
        </RectButton>
      </>
    );
  };

  return (
    <>
      <Swipeable
        enabled={Platform.OS === 'web' ? false : true}
        key={timestamp.toString()}
        ref={swipeableRef}
        renderRightActions={renderRightActions}
      >
        <TouchableOpacity
          onPress={
            navigationScreen !== NotificationNavigationScreen.None
              ? () => handleNavigation(notificationId)
              : undefined
          }
          style={styles.notificationListItemContainer}
          testID={NotificationListItemTestIDs.navigationButton}
          // Disabling tap seems to also disable tap for the children
          // This is a workaround to allow the children to remain tappable
          // even when navigation is disabled
          activeOpacity={
            navigationScreen !== NotificationNavigationScreen.None
              ? 1
              : undefined
          }
        >
          <View style={styles.notificationListItem}>
            <Icon
              size={10}
              icon={NotificationTypeIcon}
              color={isRead ? theme.palette.gray[200] : typeIconColor}
            />
            <View style={styles.notificationMessageContainer}>
              {type === 'time_sensitive' && (
                <View testID={NotificationListItemTestIDs.timeSensitiveTag}>
                  <Tag
                    icon={ClockIcon}
                    label={getText('time-sensitive')}
                    style={styles.typeTag}
                    labelColor={theme.palette.primary[600]}
                  />
                </View>
              )}
              <View style={styles.notificationMessageInnerContainer}>
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={isLongMessage && showMore ? 2 : undefined}
                  style={{
                    ...styles.readMoreText,
                    color: isRead
                      ? theme.palette.gray[700]
                      : theme.palette.gray[900],
                  }}
                >
                  {body}
                </Text>
                {isLongMessage && (
                  <View testID={NotificationListItemTestIDs.showMoreButton}>
                    <Tag
                      style={styles.showMoreButton}
                      icon={showMore ? ChevronsDownIcon : ChevronsUpIcon}
                      label={
                        showMore ? getText('show-more') : getText('show-less')
                      }
                      onPress={toggleShowMore}
                    />
                  </View>
                )}
              </View>
              <View>
                <Text style={styles.timestamp}>
                  {formatDateTimeAsString(timestamp)}
                </Text>
              </View>
              {Platform.OS === 'web' && (
                <View>
                  <TouchableOpacity
                    onPress={() => {
                      isRead
                        ? handleMarkAsUnread(notificationId)
                        : handleMarkAsRead(notificationId);
                    }}
                  >
                    <Text style={styles.readActionText}>
                      {isRead ? getText('mark-as-unread') : 'Mark as read'}
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
            {navigationScreen !== NotificationNavigationScreen.None && (
              <View style={styles.iconContainer}>
                <Icon
                  size={24}
                  icon={ChevronRightIcon}
                  color={theme.palette.gray[500]}
                />
              </View>
            )}
            {categoryIcon && (
              <Icon
                size={48}
                icon={categoryIcon}
                color={
                  isRead ? theme.palette.gray[500] : theme.colors.brandedPrimary
                }
              />
            )}
            {Platform.OS === 'web' && (
              <IconButton
                size={24}
                icon={XIcon}
                color={theme.palette.gray[500]}
                onPress={() => {
                  handleMarkAsHidden(notificationId);
                }}
                logger={{
                  id: 'web-hide-notification-button',
                }}
              />
            )}
          </View>
        </TouchableOpacity>
      </Swipeable>
      {!hideBorder && <View style={styles.borderBottom}></View>}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  notificationListItemContainer: {
    backgroundColor: theme.palette.white,
  },
  notificationListItem: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(2),
    marginTop: theme.getSpacing(2),
  },
  borderBottom: {
    height: 1,
    backgroundColor: theme.palette.gray[200],
  },
  notificationMessageContainer: {
    flex: 1,
    marginRight: getSpacing(2),
    marginLeft: getSpacing(2),
  },
  notificationMessageInnerContainer: {
    marginBottom: getSpacing(1),
  },
  typeTag: {
    marginBottom: getSpacing(1),
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    backgroundColor: 'transparent',
    marginTop: -getSpacing(0.5),
  },
  readMoreText: {
    marginTop: -getSpacing(0.5),
  },
  timestamp: {
    color: theme.palette.gray[700],
  },
  showMoreButton: {
    marginTop: getSpacing(1),
  },
  action: {
    display: 'flex',
    flex: 1,
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
    width: 65,
    alignContent: 'center',
    alignItems: 'center',
    paddingTop: theme.getSpacing(2),
  },
  actionText: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.white,
  },
  readButton: {
    marginLeft: theme.getSpacing(1),
    backgroundColor: theme.palette.primary[600],
  },
  hideButton: {
    backgroundColor: theme.palette.warning[400],
  },
  readActionText: {
    color: theme.palette.primary[600],
    paddingTop: theme.getSpacing(1),
  },
  iconContainer: {
    paddingTop: theme.getSpacing(1.5),
    paddingRight: theme.getSpacing(1.5),
  },
}));

export interface NotificationListItemProps {
  type: NotificationType;
  category: NotificationCategory;
  body: string;
  timestamp: Date;
  isRead: boolean;
  hideBorder?: boolean;
  markAsRead: (notificationId: string) => Promise<void>;
  markAsUnread: (notificationId: string) => Promise<void>;
  markAsHidden: (notificationId: string) => Promise<void>;
  handleNavigationScreen: (
    navigationScreen: NotificationNavigationScreen,
    navigationParams?: Record<string, string>,
  ) => Promise<void>;
  notificationId: string;
  refreshNotifications: () => Promise<void>;
  navigationScreen: NotificationNavigationScreen;
  navigationParams?: Record<string, string>;
  navigation: NavigationProp<ParamListBase>;
  closeMenu?: () => void;
  closeBottomSheet?: () => void;
}

export const NotificationListItemTestIDs = {
  navigationButton: 'notification-list-item-button',
  timeSensitiveTag: 'time-sensitive-tag',
  showMoreButton: 'show-more-button',
  markAsUnread: 'mark-as-unread',
  markAsRead: 'mark-as-read',
  markAsHidden: 'mark-as-hidden',
};
