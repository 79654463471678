import React, {
  useState,
  useEffect,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import {
  Text,
  View,
  StyleSheet,
  Modal,
  TouchableOpacity,
  SafeAreaView,
  Platform,
  Linking,
} from 'react-native';
import { BarCodeScanner, PermissionStatus } from 'expo-barcode-scanner';
import { Portal } from 'react-native-paper';
import { CameraAccessDenied } from '../CameraAccessDenied';
import { CornerIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { Icon } from 'assets/components/icon';

export const Barcode: FunctionComponent<PropsWithChildren<BarcodeProps>> = ({
  onScan,
  isVisible,
  onClose,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [cameraAccess, setCameraAccess] = useState<
    PermissionStatus | undefined
  >(undefined);

  const [scanned, setScanned] = useState(false);

  useEffect(() => {
    (async () => {
      if (isVisible) {
        const { status } = await BarCodeScanner.requestPermissionsAsync();
        setCameraAccess(status);
        setScanned(false);
      }
    })();
  }, [isVisible]);

  const handleBarCodeScanned = (value: { type: string; data: string }) => {
    setScanned(true);
    onScan(value.data);
  };

  return (
    <>
      <Portal>
        <Modal
          animationType="slide"
          visible={isVisible && cameraAccess !== undefined}
          presentationStyle={'fullScreen'}
          statusBarTranslucent={true}
        >
          <View style={styles.container}>
            {cameraAccess === PermissionStatus.GRANTED && (
              <View style={styles.container}>
                <BarCodeScanner
                  onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                  style={StyleSheet.absoluteFillObject}
                />
                <View
                  style={[
                    StyleSheet.absoluteFillObject,
                    { justifyContent: 'center' },
                  ]}
                >
                  <View
                    style={{
                      height: 200,
                      marginHorizontal: theme.getSpacing(4),
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <View>
                        <Icon
                          color={theme.palette.white}
                          size={64}
                          icon={CornerIcon}
                        />
                      </View>
                      <View style={{ transform: [{ scaleX: -1 }] }}>
                        <Icon
                          color={theme.palette.white}
                          size={64}
                          icon={CornerIcon}
                        />
                      </View>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <View style={{ transform: [{ scaleY: -1 }] }}>
                        <Icon
                          color={theme.palette.white}
                          size={64}
                          icon={CornerIcon}
                        />
                      </View>
                      <View
                        style={{
                          transform: [{ scaleX: -1 }, { scaleY: -1 }],
                        }}
                      >
                        <Icon
                          icon={CornerIcon}
                          color={theme.palette.white}
                          size={64}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    StyleSheet.absoluteFillObject,
                    { alignItems: 'flex-end' },
                  ]}
                >
                  <SafeAreaView
                    style={{ marginHorizontal: theme.getSpacing(1) }}
                  >
                    <TouchableOpacity
                      onPress={onClose}
                      style={{ padding: theme.getSpacing(1) }}
                    >
                      <Text
                        style={{ color: theme.palette.white, fontSize: 16 }}
                      >
                        {getText('cancel')}
                      </Text>
                    </TouchableOpacity>
                  </SafeAreaView>
                </View>
              </View>
            )}
            {cameraAccess === PermissionStatus.DENIED && (
              <CameraAccessDenied
                onSettingsPress={() => Linking.openSettings()}
                onBackPress={onClose}
              />
            )}
          </View>
        </Modal>
      </Portal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.palette.black,
    ...Platform.select({
      android: {
        paddingTop: theme.getSpacing(4),
      },
    }),
  },
}));

interface BarcodeProps {
  loading?: boolean;
  onScan: (value: string) => void;
  isVisible: boolean;
  onClose: () => void;
}
