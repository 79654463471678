import React, { FunctionComponent, PropsWithChildren } from 'react';
import Svg, { Path, Circle } from 'react-native-svg';
import { IconProps } from './types';

export const HomeIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
  strokeWidth,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path d="M7.5 21V11h6v10M1.5 8l9-7 9 7v11a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2V8Z" />
    </Svg>
  );
};
